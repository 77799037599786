<template>
    <v-app>
        <router-view v-if="!userStore.fetching" name="top"></router-view>
        <router-view v-if="!userStore.fetching" name="left"></router-view>
        <v-main class="pa-0 font">
            <v-container
                style="height: 100vh"
                v-if="userStore.fetching"
                fluid
                class="pa-0 d-flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="60"
                ></v-progress-circular>
            </v-container>
            <v-container v-else fluid class="pa-0 ma-0" style="height: 100%">
                <!-- <v-container
                v-else
                fluid
                class="pa-0 ma-0"
                style="height: 100vh; overflow-y: auto; overflow-x: hidden"
            > -->
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-alert
            v-if="errorStore.$state.error"
            title="Errore"
            class="ma-8"
            position="fixed"
            max-width="320px"
            elevation="24"
            location="top right"
            closable
            type="error"
            density="compact"
        >
            {{ errorStore.$state.error }}
        </v-alert>
        <v-alert
            v-if="appmessageStore.$state.message"
            title=""
            class="ma-8"
            position="fixed"
            max-width="320px"
            elevation="24"
            location="top right"
            closable
            type="success"
            density="compact"
        >
            {{ appmessageStore.$state.message }}
        </v-alert>
        <terms-dialog />
        <terms-company-dialog />
        <upload-box v-if="cvStore.uploading" />
    </v-app>
</template>

<script lang="ts" setup>
import { checkSession, checkOnboarding, checkViewType } from '@/composables/auth';
import { useUserStore } from '@/store/user';
import { useErrorStore } from './store/error';
import { useAppMessageStore } from './store/appmessage';

import TermsDialog from '@/components/shared/TermsDialog.vue';
import TermsCompanyDialog from '@/components/shared/TermsCompanyDialog.vue';

import UploadBox from '@/components/company/UploadBox.vue';
import { watch, onBeforeMount } from 'vue';
import { useCvUploadStore } from '@/store/cvUploads';
import { loadClarity } from '@/utils/clarity';
import i18n from '@/i18n';
import { languageTranslator } from '@/utils/helpers';

const errorStore = useErrorStore();
const appmessageStore = useAppMessageStore();

const userStore = useUserStore();
const cvStore = useCvUploadStore();

checkSession();

function setLocale(selectedLang = null) {
    if (selectedLang) {
        i18n.global.locale = selectedLang;
    } else {
        const availableLocales = i18n.global.availableLocales;
        const userLanguage = window.navigator.language.split('-')[0];
        if (availableLocales.includes(userLanguage)) {
            i18n.global.locale = userLanguage;
        } else {
            i18n.global.locale = 'en';
        }
    }
}

onBeforeMount(() => {
    setLocale();
    loadClarity();
});

watch(
    () => userStore.user,
    (data) => {
        if (data) {
            window.clarity('identify', data.id);
            window.clarity('set', 'email', data.email);
            window.clarity('set', 'userName', data.name);

            const lang = languageTranslator(data?.languageId);
            setLocale(lang);
        }
    }
);

watch(
    () => userStore.currentCompany,
    (data) => {
        if (data) {
            window.clarity('set', 'companyId', data.companyId);
            window.clarity('set', 'companyName', data.companyName);
        }
    }
);
</script>
