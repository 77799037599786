import { useAddCompanyUploadCV } from '@/composables/companies';
import { Maybe } from '@/graphql/types/graphql';
import { defineStore } from 'pinia';

export interface CvUploadState {
    searchValue: Maybe<string>;
    startSearchDate: Maybe<string>;
    endSearchDate: Maybe<string>;
    groups: unknown[];
    totalPagesGroups: number;
    currentPageGroups: number;
    loadingGroups: boolean;
    files: any[];
    totalFiles: number;
    groupId: Maybe<string>;
    uploading: boolean;
    collapsed: boolean;
    percentage: number;
    startDate: number;
    time: number;
    uploaded: number;
    errored: number;
    erroredFiles: any;
    stopUpload: boolean;
    undoUpload: boolean;
    currentFile: any;
}

export const useCvUploadStore = defineStore('cvUploads', {
    state: (): CvUploadState => {
        return {
            groups: [],
            totalPagesGroups: 0,
            currentPageGroups: 1,
            loadingGroups: false,
            files: [],
            totalFiles: 0,
            groupId: null,
            uploading: false,
            collapsed: false,
            startDate: 0,
            percentage: 0,
            time: 0,
            uploaded: 0,
            errored: 0,
            erroredFiles: [],
            stopUpload: false,
            undoUpload: false,
            currentFile: {},
            searchValue: '',
            startSearchDate: null,
            endSearchDate: null,
        };
    },
    getters: {
        uploadFinished: (state) => {
            const finished =
                state.totalFiles > 0 &&
                state.uploaded + state.errored == state.totalFiles;
            return finished;
        },
    },
    actions: {
        async uploadCv() {
            this.stopUpload = false;
            this.undoUpload = false;
            this.startDate = Date.now();
            const { mutate, onDone, onError } = useAddCompanyUploadCV();
            this.currentFile = {};

            onDone(() => {
                this.uploaded = this.uploaded + 1;
                this.percentage =
                    ((this.errored + this.uploaded) / this.totalFiles) * 100;

                if (this.percentage === 100) {
                    this.time = 0;
                } else {
                    const msSinceStart = Date.now() - this.startDate;
                    const totalTimeNeededInMs = (msSinceStart / this.percentage) * 100;
                    const stillRemainingTimeInMs = totalTimeNeededInMs - msSinceStart;

                    this.time = stillRemainingTimeInMs / 1000;
                }
                this.checkFinishedUpload();
            });

            onError(() => {
                this.errored = this.errored + 1;
                this.percentage =
                    ((this.errored + this.uploaded) / this.totalFiles) * 100;
                this.erroredFiles.push(this.currentFile);

                if (this.percentage === 100) {
                    this.time = 0;
                } else {
                    const msSinceStart = Date.now() - this.startDate;
                    const totalTimeNeededInMs = (msSinceStart / this.percentage) * 100;
                    const stillRemainingTimeInMs = totalTimeNeededInMs - msSinceStart;

                    this.time = stillRemainingTimeInMs / 1000;
                }
                this.checkFinishedUpload();
            });

            for (let i = 0; i < this.files.length; i++) {
                if (this.stopUpload) {
                    this.files = this.files.slice(i, this.files.length);
                    return;
                }

                if (this.undoUpload) {
                    return;
                }

                this.currentFile = {
                    groupId: this.groupId as string,
                    fileName: this.files[i].name as string,
                    requestId: this.startDate.toString(),
                };

                await mutate({
                    input: {
                        file: this.files[i],
                        ...this.currentFile,
                    },
                });
            }
        },
        checkFinishedUpload() {
            const uploadedFiles = this.errored + this.uploaded;
            if (uploadedFiles === this.totalFiles) {
                this.resetFiles();
            }
        },
        resetFiles() {
            this.files = [];
            this.groupId = null;
        },
        cancelUpload() {
            this.uploading = false;
            this.files = [];
            this.groupId = null;
            this.startDate = 0;
            this.percentage = 0;
            this.time = 0;
            this.uploaded = 0;
            this.errored = 0;
            this.erroredFiles = [];
        },
    },
});
