const it = {
    step: 'passaggio',
    create_password_to_start: 'Crea una password per iniziare il percorso',
    yes_subscribe_to_newsletter: 'Si, voglio iscrivermi alla newsletter',
    minimum_password_characters: 'Minimo {passwordLength} caratteri',
    welcome_to_wh: 'Ti diamo il benvenuto su Wh!',
    simple_rules: 'Ecco alcune semplici regole da tenere in mente.',
    general_conditions: 'Condizioni generali',
    who_are_you: 'Chi sei?',
    i_am_company: 'Sono un’azienda',
    and_i_search_talents: 'e sto cercando talenti',
    i_am_talent: 'Sono un talento',
    and_i_search_job: 'e sto cercando lavoro',
    my_name_is: 'Mi chiamo',
    my_birthday: 'La mia data di nascita',
    birthday: 'Data di nascita',
    your_age_will_be_public: 'La tua età sarà pubblica',
    where_i_am: 'Dove mi trovo',
    city_residence: 'Città di residenza',
    your_residence_will_be_public: 'La tua residenza sarà pubblica',
    i_d_like: 'Mi piacerebbe',
    work_from_home: 'Lavorare da casa',
    work_from_my_city: 'Lavorare dalla mia città',
    work_from_another_city: 'Lavorare da un altra città',
    work_from_another_country: "Lavorare all'estero",
    show_preference_on_profile: 'Mostra preferenza sul profilo',
    add_city: 'Aggiungi città',
    can_add_max_cities: 'Puoi aggiungere fino ad un massimo di {maxCities} città',
    city_will_be_shown: 'La città in cui ti piacerebbe lavorare apparirà su wh!',
    second_city: 'Seconda città',
    third_city: 'Terza città',
    add_nation: 'Aggiungi nazione',
    can_add_max_nations: 'Puoi aggiungere fino ad un massimo di {maxNations} nazioni',
    nation_will_be_shown: 'La nazione in cui ti piacerebbe lavorare apparirà su wh!',
    second_nation: 'Seconda nazione',
    third_nation: 'Terza nazione',
    i_am: 'Sono',
    professional_qualification: 'Qualifica Professionale',
    professional_qualification_will_be_public: 'La qualifica professionale sarà pubblica',
    experience: 'Esperienza',
    define_myself: 'Mi definisco',
    select_defects: 'Seleziona fino a {maxDefects} difetti',
    select_merits: 'Seleziona fino a {maxMerits} pregi',
    show_aptitudes_on_profile: 'Mostra attitudini sul profilo',
    start_now: 'Inizia subito',
    create_account_with_github: 'Crea account con GitHub',
    create_account_with_google: 'Continua con Google',
    create_account_with_linkedin: 'Crea account con Linkedin',
    create_account_with_apple: 'Crea account con Apple',
    signin_with_github: 'Accedi con GitHub',
    signin_with_google: 'Accedi con Google',
    signin_with_linkedin: 'Accedi con Linkedin',
    signin_with_apple: 'Accedi con Apple',
    signin_with_email: 'Accedi con email',
    mandatory_step: 'Obbligatorio (step {currentOnboardingStep} di {maxOnboardingStep})',
    i_introduce_myself: 'Mi presento',
    add_merits: 'Aggiungi pregi',
    add_defects: 'Aggiungi difetti',
    save_merits: 'Salva pregi',
    save_defects: 'Salva difetti',
    create_account_with_email: 'Crea account con email',
    show_opportunities: 'Mostrami opportunità',
    opportunities_less_than_1_year: 'Inferiori a 1 anno',
    opportunities_more_than_1_year: 'Superiori a 1 anno',
    opportunities_recruitments: 'Assunzioni',
    opportunities_freelance_and_vat_number: 'Freelance e partita iva',
    url_video_cv: 'URL video CV',
    privacy_title: 'La tua privacy è importante per Wh!',
    privacy_subtitle:
        'Noi e i nostri partner utilizziamo tracker per elaborare dati sugli utilizzatori della nostra app, personalizzare gli annunci che vedi su Wh e altrove, migliorare le operazioni di marketing di Wh e permettere l’uso delle funzionalità social.',
    personalize: 'Personalizza',
    privacy_text:
        'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not.',
    handle_permissions: 'Gestisci permessi',
    ads_auth: 'Fornisci Autorizzazioni pubblicitarie',
    ads_auth_subtitle: 'Non fornisco Autorizzazioni pubblicitarie.',
    personalize_ads_auth: 'Personalizza Autorizzazioni pubblicitarie',
    marketing_auth: 'Fornisci Autorizzazioni Marketing',
    marketing_auth_subtitle: 'Non fornisco Autorizzazioni Marketing.',
    personalize_marketing_auth: 'Personalizza Autorizzazioni Marketing',
    consent_all: 'Consenti tutti',
    reject_all: 'Rifiuta tutti',
    signup_disclaimer_1: 'Selezionando Crea Account, confermi di accettare le nostre ',
    signup_disclaimer_2: ' Scopri come elaboriamo i tuoi dati nella nostra ',
    privacyPolicyCookiePolicy: 'Informativa sulla Privacy e Informativa sui Cookie.',
    terms: 'Condizioni.',
    already_account_signin: 'Se hai già un account clicca qui per accedere',
    first_time_sign_up: 'Prima volta su Whp.ai? clicca qui per registrati',
};
export default it;
