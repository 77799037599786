import { createPinia } from 'pinia';
import { useAppMessageStore } from './appmessage';
import { useBusinessareasStore } from './businessareas';
import { useCompanyStore } from './companies';
import { useCvUploadStore } from './cvUploads';
import { useDialogsStore } from './dialogs';
import { useErrorStore } from './error';
import { useJobAnnouncementStore } from './jobAnnouncement';
import { useOnboardingStore } from './onboarding';
import { usePasswordStore } from './password';
import { usePersonalitiesStore } from './personalities';
import { useQualificationsStore } from './qualifications';
import { useSearchTalentStore } from './searchTalent';
import { useSkillsStore } from './skills';
import { useStringsOptionsStore } from './stringsOtions';
import { useTalentViewFilter } from './talentViewFilter';
import { useUploadsStore } from './uploads';
import { useUserStore } from './user';
import { useEducationStore } from './education';

export default createPinia();

export function resetStore() {
    useAppMessageStore().$reset();
    useBusinessareasStore().$reset();
    useCompanyStore().$reset();
    useCvUploadStore().$reset();
    useDialogsStore().$reset();
    useErrorStore().$reset();
    useJobAnnouncementStore().$reset();
    useOnboardingStore().$reset();
    usePersonalitiesStore().$reset();
    useQualificationsStore().$reset();
    useSearchTalentStore().$reset();
    useSkillsStore().$reset();
    useStringsOptionsStore().$reset();
    useTalentViewFilter().$reset();
    useUploadsStore().$reset();
    useUserStore().$reset();
    usePasswordStore().$reset();
    useEducationStore().$reset();
}
