const en = {
    add: 'Add',
    continue: 'Continue',
    start: 'Start',
    sign_in: 'Sign in',
    create_account: 'Create account',
    exit: 'Exit',
    choose: 'Choose',
    of: 'of',
    email: 'Email',
    password: 'Password',
    new_password: 'New Password',
    current_password: 'Current Password',
    current_password_error: 'The current password is incorrect',
    password_changed_success: 'Password changed successfully',
    password_changed_error: 'Error - Password was not changed correctly',
    repeat_password: 'Repeat password',
    repeat_new_password: 'Repeat new password',
    edit_password: 'Edit password',
    accept: 'I accept',
    name: 'Name',
    surname: 'Surname',
    all: 'All',
    city: 'City√†',
    nation: 'Nation',
    or_else: 'or',
    save: 'Save',
    jump: 'Jump',
    hello: 'Hello',
    verify_email_message:
        'we recommend that you verify your email address to continue using the platform',
    verify_email: 'Send again',
    view_type_company: 'Switch to company mode',
    view_type_user: 'Switch to talent mode',
    admin_dashboard: 'Admin Dashboard',
    italian: 'Italian',
    english: 'English',
    account: 'Account',
    logout: 'Logout',
    edit: 'Edit',
    preview: 'Preview',
    description: 'Description',
    title: 'Title',
    complete: 'Complete',
    settings: 'Settings',
    and: 'and',
    search_skills: 'Search skills',
    step: 'Step',
    skills: 'Skills',
    yes: 'Yes',
    no: 'No',
    experience_year: 'Years of experience',
    back: 'Back',
    next: 'Next',
    publish: 'Publish',
    add_skills: 'Add skills',
    save_skills: 'Save skills',
    dashboard_menu: 'Dashboard',
    search_menu: 'Search for companies and opportunities',
    jobs_menu: 'Jobs',
    match_menu: 'Match',
    learning_menu: 'Training',
    empty_params: 'Please enter all fields',
    messages: 'Messages',
    notifications: 'Notifications',
    recent_searches: 'My searches',
    seconds: 'seconds',
    save_photo: 'Save photo',
    cancel: 'Cancel',
    typology: 'Typology',
    role: 'Role',
    agency: 'Agency',
    search: 'Search',
    refused: 'Not approved',
    confirm_action: 'Are you sure you want to perform this action?',
    coverimage: 'cover image',
    previewimage: 'preview image',
    photo: 'Profile photo',
    subscriptions: 'Subscriptions',
    advanced_search: 'Advanced search',
    phone: 'Telephone',
    uploaded: 'Processing',
    completed: 'Processed',
    search_history: 'Search history',
    active: 'active',
    closed: 'closed',
    check_email: 'Check your email',
    check_email_text: 'To continue using the Wh platform, verify your email address!',
    check_email_subtext: "Didn't receive the email? Request it again.",
    selected: 'selected',
    drafted: 'discarded',
    upload_status: {
        open: 'Open',
        completed: 'Completed',
        created: 'To load',
        uploaded: 'Uploaded',
        processing: 'Processing',
    },
    years: 'years',
    check_birthdate: 'Check your birth date',
    signin_disclaimer:
        'By selecting Sign in, you confirm that you accept our Terms. Find out how we process your data in our Privacy Policy and Cookie Policy',
    show_all: 'Show all',
    filter: 'Filter by',
    expired: 'Expired',
    expire: 'Expired',
    close: 'Close',
    description_too_long: 'Check description',
    start_title_1: 'Artificial Intelligence for',
    start_title_2: 'strategic recruiting and',
    start_title_3: 'HR data intelligence.',
    start_subtitle_1: 'Analyze and enrich CV data.',
    start_subtitle_2: 'Discover candidate potential. Configure the best team. ',
    download_app: 'Download the app',
    trusted_by: 'supported by',
    selected_in: 'selected in',
    filter_by: 'Filter by',
    signup_on_wh: 'Sign up on WH',
    offer_detail: 'Offer details',
    candidature_send: 'Send application',
    candidate_thank_you: 'Thank you for submitting your application.',
    candidate_thank_you_message:
        'We will evaluate your profile and if in line with the search you will be contacted.',
    candidate_thank_you_message_2:
        'While you wait you can register on Wh to have access to other opportunities!',
    look_for_talents: 'Search for candidates',
    create_team_list: 'Create team list',
    saved_profiles: 'Saved Profiles',
    my_team_list: 'My team lists',
    upload_cv: 'Upload CV',
    post_job_offers: 'Post job offers',
    interview_diary: 'Interview diary',
    remote_work: 'Work remotely',
    remote_work_mobile: 'Remote',
    work_on_site: 'Work on site',
    work_on_site_mobile: 'On site',
    hybrid_work: 'Hybrid',
    enter_your_workplace: 'Enter your location',
    duration: 'Duration',
    activate: 'Activate',
    openhr_requests_list: 'Requests list',
    complete_profile: 'Complete profile',
    complete_profile_message: 'Complete your profile on Wh to apply for offers',
    complete_profile_message_2: 'and to find out about other opportunities!',
    no_job_announcement_message: "we're sorry but there are no announcements for you",
    copy_url: 'Copy url',
    url_copied: 'Url copied',
    home: 'Home',
    open_hr: 'Open HR',
    where: 'Where',
    my_cv: 'My CVs',
    involves: 'Implicates',
    relates: 'Relate',
    file_name: 'File name',
    qualification: 'Qualification',
    corresponding: 'correspondence',
    junior: 'Junior',
    middle: 'Middle',
    senior: 'Senior',
    small: 'Small',
    medium: 'Medium',
    big: 'Big',
    age: 'Age',
    seniority: 'Seniority',
    privacy_cookie: 'Privacy and Cookie Policy',
    terms_and_conditions: 'Terms and Conditions',
    terms_of_service: 'Terms of Service',
    service_center: 'Help Center',
    invalid_vat:
        'Invalid VAT number format. Enter the VAT number preceded by the country code (e.g. IT12345678901)',
    forgot_password: 'I forgot my password',
    reset_password: 'Reset Password',
    reset_password_text:
        'Enter your email and click to receive an email with instructions for setting a new password',
    send_email: 'Send email',
    reset_password_email_sent:
        'Password reset email sent successfully. Click on the link we sent you and enter a new password',
    reset_password_email_sent_error: 'Error resetting password. Try again',
    create_new_password: 'Create a new password to continue on Wh!',
    click_to_retry: 'Click here to start again.',
    password_reset_ko: 'Error. The password was not set correctly.',
    password_reset_ok: 'Your password has been set successfully.',
    go_to_login: 'Click here to log in to WH!',
    companyName: 'Company name',
    message: 'Message',
    send: 'Send',
    where_i_am: 'Where am I',
    login_error: 'Login error. Incorrect credentials',
    last_update: 'Last update',
    upload_date: 'CV upload date',
    required_field: '* Required field',
    no_skills: 'There are no skills that match your search',
    summary_generated_by: 'Summary generated by:',
    email_sent: 'Email sent successfully',
    check_form_fields: 'Check form fields',
    email_sent_error: 'We encountered a problem sending the email.',
    not_specified: 'Not specified',
    select: 'Select',
    not_available: 'Not available',
    open_recruiting: 'Go to the HR Recruiting area',
    view: 'View',
    dashboard_education: 'Dashboard Education',
    create_training_courses: 'Create training courses',
    succession_plan: 'Succession plan',
    my_courses: 'My courses',
    initiative_cofinanced: 'INITIATIVE CO-FINANCED BY THE EUROPEAN UNION',
    puglia: 'THROUGH THE P.O PUGLIA - FESR FSE 2014-2020',
    footer_info_1:
        'Axis I - Research, Technological Development and Innovation / Action 1.5',
    footer_info_2:
        'Axis III - Competitiveness of small and medium-sized enterprises / Action 3.8',
    footer_label_tecnonidi:
        'TECHNONIDE MEASUREMENT - PROJECT CARRIED OUT BY THE WH TECH SRL COMPANY',
    footer_address:
        'Operational headquarters: Corso Vittorio Emanuele II, 30 - 70122 Bari (BA), Italy',
};
export default en;
