<template>
    <v-menu class="menu-custom">
        <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
                :color="getButtonColor()"
                variant="outlined"
                elevation="0"
                rounded="pill"
            >
                <v-icon :color="getTextIconColor()" class="mr-2">mdi-web</v-icon>
                <div :class="`text-${getTextIconColor()}`">{{ getTitle() }}</div>
                <v-icon v-if="!isSmallDevice()" :color="getTextIconColor()" class="ml-2">
                    mdi-menu-down
                </v-icon>
            </v-btn>
        </template>
        <v-list class="font" style="margin-left: -50px">
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :value="index"
                @click="changeLang(item.lang)"
            >
                <v-list-item-title class="text-secondary text-body-2">
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts" setup>
import i18n from '@/i18n';
import { defineProps, withDefaults } from 'vue';
import { useI18n } from 'vue-i18n';
import { isSmallDevice } from '@/utils/helpers';
import { useUpdateUser } from '@/composables/user';
import { useUserStore } from '@/store/user';
import { getLanguageId } from '@/utils/helpers';

const {
    mutate: updateUser,
    loading: loadingUpdate,
    onDone: onDoneUpdate,
} = useUpdateUser();

const { t } = useI18n();
const userStore = useUserStore();

const items = [
    { title: t('italian'), lang: 'it' },
    { title: t('english'), lang: 'en' },
];

interface LanguageMenuProps {
    showTitle: boolean;
    theme: string;
}

const props = withDefaults(defineProps<LanguageMenuProps>(), {
    showTitle: true,
    theme: 'white',
});

function getTitle() {
    let lang = t('italian');
    if (i18n.global.locale == 'en') {
        lang = t('english');
    }
    return props.showTitle ? lang : '';
}

function getButtonColor() {
    const color = props.theme === 'white' ? 'lightGray' : 'white';
    return color;
}

function getTextIconColor() {
    const color = props.theme === 'white' ? 'subtitle' : 'white';
    return color;
}

async function changeLang(lang) {
    console.log('lang', lang);
    i18n.global.locale = lang;
    const newLang = getLanguageId(lang);
    await updateUser({
        id: userStore.getUserId,
        languageId: newLang,
    });
}
</script>
<style scoped>
.v-list-item.v-theme--lightTheme.v-list-item--density-default.v-list-item--one-line.v-list-item--variant-text:hover {
    background-color: #efefef;
}
</style>
