import {
    EducationCourse,
    EducationLesson,
    EducationSearchUser,
    EducationStudent,
    Maybe,
} from '@/graphql/types/graphql';

import { defineStore } from 'pinia';

export type CourseBasicInfo = {
    title: string;
    subjectarea: string | null;
    type: string;
    address: string;
    availability: string;
    timing: string;
    status: string;
    ownerId: string;
    companyOwnerId: string;
};

export type LessonInfo = {
    id: Maybe<string>;
    title: string;
    info: string;
    lesson_url: string;
    trainerId: Maybe<string>;
    date: Date;
    timeStart: {
        hours: number;
        minutes: number;
    };
    timeEnd: {
        hours: number;
        minutes: number;
    };
    courseId: string;
};

export interface EducationState {
    newCourse: any;
    newCourseBasicInfo: CourseBasicInfo;
    searchFilters: any;
    searchFiltersStudentCourse: any;
    requestRegistration: boolean;
    course: Maybe<EducationCourse>;
    newLesson: LessonInfo;
    selectedPartecipant: Maybe<EducationSearchUser | EducationStudent>;
    selectedPartecipants: EducationSearchUser[] | EducationStudent[];
}

export const useEducationStore = defineStore('education', {
    state: (): EducationState => {
        return {
            newCourse: {},
            newCourseBasicInfo: {
                title: '',
                subjectarea: null,
                type: '',
                address: '',
                availability: '',
                timing: '',
                status: '',
                ownerId: '',
                companyOwnerId: '',
            },
            searchFilters: {
                availability: [],
                lessons_from: '',
                hours_from: '',
                type: [],
                subjectarea: [],
                timing: [],
            },
            searchFiltersStudentCourse: {
                availability: [],
                lessons_from: '',
                hours_from: '',
                type: [],
                subjectarea: [],
                timing: [],
            },
            requestRegistration: false,
            course: null,
            newLesson: {
                id: null,
                title: '',
                info: '',
                lesson_url: '',
                trainerId: null,
                date: new Date(),
                timeStart: {
                    hours: 8,
                    minutes: 0,
                },
                timeEnd: {
                    hours: 9,
                    minutes: 0,
                },
                courseId: '',
            },
            selectedPartecipant: null,
            selectedPartecipants: [],
        };
    },
    getters: {
        canAddLesson(): boolean {
            return (
                !!this.newLesson.title &&
                !!this.newLesson.trainerId &&
                !!this.newLesson.timeStart &&
                !!this.newLesson.timeEnd &&
                !!this.newLesson.date &&
                this.isNewLessonTimeOk
            );
        },
        isNewLessonTimeOk(): boolean {
            if (this.newLesson.timeStart != null && this.newLesson.timeEnd != null) {
                if (this.newLesson.timeEnd.hours > this.newLesson.timeStart.hours) {
                    return true;
                } else if (
                    this.newLesson.timeEnd.hours == this.newLesson.timeStart.hours
                ) {
                    return (
                        this.newLesson.timeEnd.minutes > this.newLesson.timeStart.minutes
                    );
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
    actions: {
        resetNewCourse() {
            this.newCourse = {};
            this.newCourseBasicInfo = {
                title: '',
                subjectarea: null,
                type: '',
                address: '',
                availability: '',
                timing: '',
                status: '',
                ownerId: '',
                companyOwnerId: '',
            };
        },
        resetCourse() {
            this.course = null;
        },
        resetSearchFilters() {
            this.searchFilters = {
                availability: [],
                lessons_from: '',
                hours_from: '',
                type: [],
                subjectarea: [],
                timing: [],
            };
        },
        initNewLesson(lessonToEdit: Maybe<EducationLesson> = null) {
            this.newLesson = {
                id: lessonToEdit?.id ? lessonToEdit.id : null,
                title: lessonToEdit?.title ? lessonToEdit.title : '',
                info: lessonToEdit?.info ? lessonToEdit.info : '',
                lesson_url: lessonToEdit?.lesson_url ? lessonToEdit.lesson_url : '',
                trainerId: lessonToEdit?.teacherId ? lessonToEdit?.teacherId : null,
                date: lessonToEdit ? new Date(lessonToEdit.date as string) : new Date(),
                timeStart: {
                    hours: lessonToEdit
                        ? (parseInt(
                              lessonToEdit.from?.substring(0, 2) as string
                          ) as number)
                        : 8,
                    minutes: lessonToEdit
                        ? (parseInt(
                              lessonToEdit.from?.substring(3, 5) as string
                          ) as number)
                        : 0,
                },
                timeEnd: {
                    hours: lessonToEdit
                        ? (parseInt(lessonToEdit.to?.substring(0, 2) as string) as number)
                        : 9,
                    minutes: lessonToEdit
                        ? (parseInt(lessonToEdit.to?.substring(3, 5) as string) as number)
                        : 0,
                },
                courseId: '',
            };
        },
        increaseNumberOfLesson() {
            if (this.newCourse.lessons) {
                this.newCourse.lessons += 1;
            } else {
                this.newCourse.lessons = 1;
            }
        },
        decreaseNumberOfLesson() {
            if (this.newCourse.lessons && this.newCourse.lessons > 0) {
                this.newCourse.lessons -= 1;
            }
        },
        changeStudentNumberOfLesson(value: string) {
            if (value && value !== '') {
                this.newCourse.lessons = parseInt(value);
            } else {
                this.newCourse.lessons = null;
            }
        },
        increaseHours() {
            if (this.newCourse.hours) {
                this.newCourse.hours += 1;
            } else {
                this.newCourse.hours = 1;
            }
        },
        decreaseHours() {
            if (this.newCourse.hours && this.newCourse.hours > 0) {
                this.newCourse.hours -= 1;
            }
        },
        changeStudentHours(value: string) {
            if (value && value !== '') {
                this.newCourse.hours = parseInt(value);
            } else {
                this.newCourse.hours = null;
            }
        },

        increaseStudentMin() {
            if (this.newCourse.students_min) {
                this.newCourse.students_min += 1;
            } else {
                this.newCourse.students_min = 1;
            }
        },
        decreaseStudentMin() {
            if (this.newCourse.students_min && this.newCourse.students_min > 0) {
                this.newCourse.students_min -= 1;
            }
        },
        changeStudentMin(value: string) {
            if (value && value !== '') {
                this.newCourse.students_min = parseInt(value);
            } else {
                this.newCourse.students_min = null;
            }
        },

        increaseStudentMax() {
            if (this.newCourse.students_max) {
                this.newCourse.students_max += 1;
            } else {
                this.newCourse.students_max = 1;
            }
        },
        decreaseStudentMax() {
            if (this.newCourse.students_max && this.newCourse.students_max > 0) {
                this.newCourse.students_max -= 1;
            }
        },
        changeStudentMax(value: string) {
            if (value && value !== '') {
                this.newCourse.students_max = parseInt(value);
            } else {
                this.newCourse.students_max = null;
            }
        },
    },
});
