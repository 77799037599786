export enum RoutesNames {
    root = 'root',
    dashboard = 'dashboard',
    onboarding_start = 'onboarding_start',
    onboarding_setPassword = 'onboarding_setPassword',
    onboarding_terms = 'onboarding_terms',
    onboarding_whoareyou = 'onboarding_whoareyou',
    onboarding_name = 'onboarding_name',
    onboarding_birthday = 'onboarding_birthday',
    onboarding_city = 'onboarding_city',
    onboarding_addcity = 'onboarding_addcity',
    onboarding_where_work = 'onboarding_where_work',
    onboarding_qualification = 'onboarding_qualification',
    onboarding_personality_merits = 'onboarding_personality_merits',
    onboarding_personality_defects = 'onboarding_personality_defects',
    onboarding_step_1 = 'onboarding_step_1',
    onboarding_step_2 = 'onboarding_step_2',
    edit_company_profile = 'edit_company_profile',
    user_profile = 'user_profile',
    edit_user_profile = 'edit_user_profile',
    edit_user_cv = 'edit_user_cv',
    privacy = 'privacy',
    personalize_privacy = 'personalize_privacy',
    subscription = 'subscription',
    confirmEmail = 'confirmEmail',
    userconfirm = 'userconfirm',
    resetPassword = 'resetPassword',
    signIn = 'signIn',
    signUp = 'signUp',
    signup_talent = 'signup_talent',
    company_terms = 'company_terms',
    privacy_cookie = 'privacy_cookie',
    terms_conditions = 'terms_conditions',
    terms_services = 'terms_services',
    help = 'help',
    profile_settings = 'profile_settings',
    not_found = 'not_found',
    manteinance = 'manteinance',
    company_dashboard = 'company_dashboard',
    company_dashboard_courses_list = 'company_dashboard_courses_list',
    company_dashboard_courses_trainers = 'company_dashboard_courses_trainers',
    company_dashboard_courses = 'company_dashboard_courses',
    company_dashboard_courses_create = 'company_dashboard_courses_create',
    company_dashboard_courses_create_step_1 = 'company_dashboard_courses_create_step_1',
    company_dashboard_courses_create_step_2 = 'company_dashboard_courses_create_step_2',
    company_dashboard_courses_create_step_3 = 'company_dashboard_courses_create_step_3',
    dashboard_enrollment = 'dashboard_enrollment',
    dashboard_nextLessons = 'dashboard_nextLessons',
    company_dashboard_courses_detail = 'company_dashboard_courses_detail',
    dashboard_course_details = 'dashboard_course_details',
}
