import { DocumentParameter, OptionsParameter } from './apollo';

import { OperationVariables } from '@apollo/client/core';
import { VariablesParameter } from '@vue/apollo-composable/dist/useQuery';
import { useQuery } from '@vue/apollo-composable';

const DEFAULT_FETCH_POLICY = 'no-cache';

export default function query<
    TResult = any,
    TVariables extends OperationVariables = OperationVariables
>(
    document: DocumentParameter<TResult, TVariables>,
    variables: VariablesParameter<TVariables> | null = null,
    options: OptionsParameter<TResult, null> = {}
) {
    if (variables == null) {
        return useQuery(document);
    }
    return useQuery(document, variables, {
        ...options,
        fetchPolicy: DEFAULT_FETCH_POLICY,
    });
}
