import { defineStore } from 'pinia';
import _ from 'lodash';

export interface Filters {
    selected: Options[];
}

export enum Options {
    contact_info = 'contact_info',
    talent_score = 'talent_score',
    suggestions = 'suggestions',
    inferences_map = 'inferences_map',
    experience = 'experience',
    soft_skills = 'soft_skills',
    evaluations = 'evaluations',
}

export const useTalentViewFilter = defineStore('talentViewFilter', {
    state: (): Filters => {
        return {
            selected: [
                Options.contact_info,
                Options.talent_score,
                Options.suggestions,
                Options.inferences_map,
                Options.experience,
                Options.soft_skills,
                Options.evaluations,
            ],
        };
    },
    actions: {
        setFilter(filter: Options) {
            if (this.selected.includes(filter)) {
                _.remove(this.selected, (element) => element === filter);
            } else {
                this.selected.push(filter);
            }
        },
    },
});
