import { Company, CompanyPublic, SavedSearch } from '@/graphql/types/graphql';

import { Maybe } from 'graphql/jsutils/Maybe';
import { defineStore } from 'pinia';

export interface CompanyState {
    userCompany: Partial<Maybe<Company>>;
    companies: Maybe<Company[]>;
    publicCompanies: Maybe<CompanyPublic[]>;
    loading: boolean;
    savedSearches: Maybe<SavedSearch[]>;
}

export const useCompanyStore = defineStore('companies', {
    state: (): CompanyState => {
        return {
            userCompany: null,
            companies: null,
            publicCompanies: null,
            loading: false,
            savedSearches: [],
        };
    },
});
