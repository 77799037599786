<template>
    <div :style="getStyle()">
        <div class="d-flex">
            <div
                class="pl-6 d-row align-center justify-start v-col-sm-5 v-col-md-3 v-col-lg-3 v-col-xl-2 v-col-12"
            >
                <v-img
                    v-if="props.theme === 'white'"
                    height="50"
                    width="50"
                    class="cursor"
                    @click="checkViewType"
                    src="@/assets/images/logo-color.svg"
                ></v-img>
                <v-img
                    v-else
                    height="50"
                    width="50"
                    class="cursor"
                    @click="checkViewType"
                    src="@/assets/images/logo-white.svg"
                ></v-img>
            </div>
            <div
                v-if="route.name == RoutesNames.company_dashboard"
                class="d-flex d-row align-center justify-start"
            >
                <v-btn
                    v-for="dashboard in dashboards"
                    :key="dashboard.label"
                    elevation="0"
                    variant="outlined"
                    :color="getDashboardButtonColor(dashboard.route as RoutesNames)"
                    rounded="pill"
                    :class="dashboard.class"
                    @click="openDashboard(dashboard.label)"
                >
                    <div
                        :class="
                            getDashboardButtonTextStyle(dashboard.route as RoutesNames)
                        "
                    >
                        {{ dashboard.label }}
                    </div>
                </v-btn>
            </div>
            <div
                class="d-flex align-center justify-center"
                style="flex: 1"
                v-if="
                    !userStore.viewTypeUser &&
                    !isSmallDevice() &&
                    route.name != RoutesNames.company_dashboard
                "
            >
                <v-btn
                    :color="colorButton(RoutesNames.company_dashboard_courses_create)"
                    density="comfortable"
                    v-bind="props"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.company_dashboard_courses_create)"
                >
                    {{ $t('create_course') }}
                </v-btn>
                <v-btn
                    :color="colorButton(RoutesNames.company_dashboard_courses_list)"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.company_dashboard_courses_list)"
                >
                    {{ $t('course_list') }}
                </v-btn>
                <v-btn
                    :color="colorButton(RoutesNames.company_dashboard_courses_trainers)"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.company_dashboard_courses_trainers)"
                >
                    {{ $t('course_trainer') }}
                </v-btn>
            </div>
            <div
                class="d-flex align-center justify-center"
                style="flex: 1"
                v-if="userStore.viewTypeUser && !isSmallDevice()"
            >
                <v-btn
                    :color="colorButton(RoutesNames.dashboard)"
                    density="comfortable"
                    v-bind="props"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.dashboard)"
                >
                    {{ $t('course_list') }}
                </v-btn>
                <v-btn
                    :color="colorButton(RoutesNames.dashboard_enrollment)"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.dashboard_enrollment)"
                >
                    {{ $t('course_subscription') }}
                </v-btn>
                <v-btn
                    :color="colorButton(RoutesNames.dashboard_nextLessons)"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-6 text-none"
                    @click="goTo(RoutesNames.dashboard_nextLessons)"
                >
                    {{ $t('course_next_lessons') }}
                </v-btn>
            </div>
            <div class="d-flex align-center justify-end" style="flex: 1">
                <v-btn
                    v-if="
                        showActionMenu() &&
                        !showOtherElementsMenu() &&
                        hasMoreCompany() &&
                        props.type !== 'public'
                    "
                    :color="props.theme === 'white' ? 'subtitle' : 'white'"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-4 text-none"
                    @click="dialogsStore.changeCompany = true"
                >
                    {{ $t('change_company') }}
                </v-btn>
                <language-menu
                    v-if="showOtherElementsMenu() || props.type === 'public'"
                    :showTitle="!!userStore.viewTypeUser"
                    :theme="props.theme"
                />
                <div class="mr-4" />
                <company-profile-menu
                    v-if="
                        showActionMenu() && showCompanyMenu() && props.type !== 'public'
                    "
                    :company="userStore.currentCompany"
                    :theme="props.theme"
                />
                <div class="mr-4" />
                <user-profile-menu
                    v-if="showActionMenu() && !!userStore.user && props.type !== 'public'"
                    :user="userStore.user"
                    :isTalent="
                        userStore.viewTypeUser === null ? false : userStore.viewTypeUser
                    "
                    :theme="props.theme"
                    :company="userStore.currentCompany"
                />
                <v-btn
                    v-if="!showActionMenu() && userStore.user && props.type !== 'public'"
                    class="font ml-6"
                    flat
                    variant="text"
                    rounded="pill"
                    color="title"
                    @click="signoutUser"
                >
                    {{ $t('exit') }}
                </v-btn>
            </div>
            <div class="mr-4" />
        </div>
        <add-company-dialog
            @companyAdded="companyAdded"
            @companyAddSelected="companyAddSelected"
        />
        <change-company-dialog @companySelected="companySelected" />
        <v-divider
            v-if="!showOtherElementsMenu() && props.type !== 'public'"
            color="primary"
            thickness="4"
        />
        <v-divider v-else />
    </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/user';
import LanguageMenu from '@/components/structure/LanguageMenu';
import UserProfileMenu from '@/components/structure/UserProfileMenu';
import CompanyProfileMenu from '@/components/structure/CompanyProfileMenu';
import AddCompanyDialog from '@/components/company/AddCompanyDialog';
import ChangeCompanyDialog from '@/components/company/ChangeCompanyDialog';
import { useI18n } from 'vue-i18n';
import { withDefaults, defineProps } from 'vue';
import { useChangeCurrentAppVisualizzation } from '@/composables/user';
import { useAddCompany, useAddMeToCompany } from '@/composables/companies';
import { useDialogsStore } from '@/store/dialogs';
import { useRouter, useRoute } from 'vue-router';
import { RoutesNames } from '@/router/routesNames';
import { checkViewType } from '@/composables/auth';
import { signoutUser, goToDashboard } from '@/composables/auth';
import { isSmallDevice } from '@/utils/helpers';
import { EnvVars, getEnv } from '@/utils/env';

const router = useRouter();
const route = useRoute();
const dialogsStore = useDialogsStore();
const { t } = useI18n();

const dashboards = [
    {
        label: 'Hiring & Staffing',
        route: '',
        class: '',
    },
    {
        label: 'Management',
        route: '',
        class: 'ml-4',
    },
    {
        label: 'Training',
        route: RoutesNames.company_dashboard,
        class: 'ml-4',
    },
];

interface AppNavbarProps {
    theme?: string;
    type?: string;
}

const props = withDefaults(defineProps<AppNavbarProps>(), {
    theme: 'white', // DEFAULT,
    type: 'internal',
});

const userStore = useUserStore();

const {
    mutate: changeCurrentAppVisualizzation,
    loading: loadingUpdate,
    onDone: onDonechangeCurrentAppVisualizzation,
} = useChangeCurrentAppVisualizzation();

const {
    mutate: addCompany,
    loading: loadingAdd,
    onDone: onDoneAdd,
    result: resultAddCompany,
} = useAddCompany();

const {
    mutate: addMeToCompany,
    loading: loadingAddMe,
    onDone: onDoneAddMe,
} = useAddMeToCompany();

function companyApproved() {
    return !!userStore.currentCompany?.company_approval_date;
}

function userApproved() {
    return (
        !!userStore.currentCompany?.user_approval_date &&
        userStore.currentCompany.user_approval_date !== ''
    );
}

function showActionMenu() {
    if (!userStore.user) {
        return false;
    }
    if (
        !userStore.privacyAccepted ||
        !userStore.emailVerified ||
        !userStore.secondTermsAccepted
    ) {
        return false;
    }
    return true;
}

function showCompanyMenu() {
    const show = !userStore.viewTypeUser && !!companyApproved() && !!userApproved();
    return show;
}

function showOtherElementsMenu() {
    return showCompanyMenu() || !!userStore.viewTypeUser;
}

function hasCompany() {
    return userStore.allCompanies?.length > 0;
}
function hasMoreCompany() {
    return userStore.allCompanies?.length > 1;
}

async function companyAdded(company) {
    await addCompany({
        input: company,
    });
}

async function companyAddSelected({ company, role }) {
    await addMeToCompany({ role: role.toUpperCase(), companyId: company.id });
}

async function companySelected(companyId) {
    await changeCurrentAppVisualizzation({
        companyId,
    });
}
onDonechangeCurrentAppVisualizzation(() => {
    goToDashboard();
});

function goTo(routeName: RoutesNames) {
    router.push({ name: routeName });
}

function colorButton(routeName: RoutesNames) {
    if (routeName === RoutesNames.company_dashboard_courses_create) {
        const routesJob = [RoutesNames.company_dashboard_courses_create];
        if (routesJob.includes(route.name as string)) {
            return 'primary';
        }
    } else {
        if ((route.name as string) === routeName) {
            return 'primary';
        }
    }
    return props.theme === 'white' ? 'subtitle' : 'white';
}

function getStyle() {
    if (props.theme === 'white') {
        return 'z-index: 1; background-color: #fff';
    }
    return '';
}

function getDashboardButtonTextStyle(routeName: RoutesNames) {
    var style = 'font text-body-2 ';
    if (route.name == routeName) {
        return style + 'font-weight-bold text-primary';
    } else return style + 'text-secondary';
}

function getDashboardButtonColor(routeName: RoutesNames) {
    if (route.name == routeName) {
        return 'primary';
    } else return 'lightGray';
}

function openDashboard(name: string) {
    if (name == 'Hiring & Staffing') {
        window.location.href = getEnv(EnvVars.VUE_APP_DOMAIN) + '/company/hiring';
    } else if (name == 'Management') {
        window.location.href = getEnv(EnvVars.VUE_APP_DOMAIN) + '/company/management';
    }
}
</script>
