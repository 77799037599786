const it = {
    edit_profile: 'Modifica profilo',
    handle_cv: 'Gestione CV',
    competence_title: 'Vuoi arricchire le tue competenze?',
    competence_subtitle: 'iscriviti a nostri corsi avanzati',
    competence_button: 'Scopri i nostri corsi',
    knowledge_title: 'Vuoi arricchire le tue conoscenze?',
    knowledge_subtitle: 'iscriviti al nostro canale telegram',
    knowledge_button: 'Scopri i nostri webinar',
    choose_hobby: 'Inserisci un tuo hobby',
    profile_photo: 'Foto profilo',
    video_cv: 'Video CV',
    about_me: 'Qualcosa su di me',
    other: 'Altro',
    languages: 'Lingue',
    hobby: 'Hobby',
    social: 'Social',
    link: 'Link',
    certificates: 'Attestati',
    certifications: 'Certificazioni',
    questionnaires: 'Questionari',
    insert_certificate: 'Inserisci un attestato',
    insert_certification: 'Inserisci una certificazione',
    spoken_languages: 'Lingue parlate',
    add_your_cv: 'Aggiungi il tuo nuovo CV',
    cv_uploaded: 'Curriculum caricati',
    select_language: 'Seleziona una lingua',
    update_user_message: 'Profilo aggiornato con successo!',
    profile: 'Profilo',
    account_setting: 'Account',
    add_video_cv: 'Aggiungi il tuo video CV',
    upload_video: 'Carica video',
    add_link_video: 'Oppure inserisci link al video CV',
    view_all_text_privacy: 'Visualizza il testo completo',
    open_to_work: 'Open to work',
    by_setting_the_mode_on:
        'Impostando la modalità ON hai accesso alle funzionalità di ricerca lavoro. Gli altri utenti non sapranno che sei Open to work',
    profile_settings: {
        in_remote: 'In remoto',
        offerts_distance: 'Distanza massima offerte',
        phone_number: 'Numero di telefono',
        connected_accounts: 'Account collegati',
        other_settings: 'Altre impostazioni',
        show_opportunities: 'Mostrami opportunità',
        app_languages: 'Lingua dell’applicazione',
        others_cities_and_nations: 'Ulteriori città e Nazioni',
        notification_preference: 'Preferenze notifiche',
        privacy: 'Privacy',
        cookie_policy: 'Cookie Policy',
        privacy_policy: 'Privacy Policy',
        terms_and_conditions: 'Termini e condizioni',
        legal_notes: 'Note legali',
        delete_account: 'Elimina account',
    },
};
export default it;
