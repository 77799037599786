<template>
    <div>
        <v-select
            v-if="props.type !== 'editTalent'"
            v-model="selectedType"
            :items="typesList"
            class="mt-8 mb-8"
            :label="`${$t('typology')} *`"
            variant="underlined"
            hide-details
            color="primary"
        ></v-select>
        <v-autocomplete
            v-model="model"
            v-model:search="search"
            :disabled="isDisabled()"
            :items="businessareasStore.filtered"
            hide-no-data
            underlined
            :clearable="true"
            @click:clear="
                props.type !== 'editTalent'
                    ? (companyStore.userCompany.businessArea = null)
                    : props.clear()
            "
            hide-selected
            color="primary"
            class="no-padding-start"
            :label="`${$t('businessarea')} *`"
            :placeholder="`${$t('businessarea')}`"
        ></v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import configuration from '@/composables/config';
import { getBusinessAreas } from '@/composables/businessareas';
import { useCompanyStore } from '@/store/companies';
import { useBusinessareasStore } from '@/store/businessareas';
import { computed, ref, watch, defineEmits, withDefaults, defineProps } from 'vue';
import _ from 'lodash';
import { Businessarea, Maybe, BusinessareaType } from '@/graphql/types/graphql';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['select']);

let companyStore = useCompanyStore();
let businessareasStore = useBusinessareasStore();
let search = ref('');

let selectedType = ref(getInitialType());

interface BusinessareaSelectorProps {
    type?: string;
    value?: Maybe<Businessarea>;
    clear?: () => void;
}

const props = withDefaults(defineProps<BusinessareaSelectorProps>(), {
    type: 'edit', // DEFAULT,
    value: null,
});

function isDisabled() {
    if (props.type === 'editTalent') {
        return false;
    }
    return !selectedType.value;
}

function getInitialType() {
    if (props.type === 'edit') {
        if (companyStore.userCompany?.businessArea) {
            return t(
                (companyStore.userCompany.businessArea.type as string).toLowerCase()
            );
        }
    } else {
        if (props.value) {
            return t((props.value.type as string).toLowerCase());
        }
    }
    return null;
}

const types = Object.values(BusinessareaType)
    .filter((el) => {
        return (el as string).toLowerCase() !== 'agency';
    })
    .map((el) => ({
        label: t(`business_area_${(el as string).toLowerCase()}`),
        value: el,
    }));

const typesList = types.map((el) => el.label);

const model = computed({
    get() {
        if (props.type === 'edit') {
            return companyStore.userCompany?.businessArea?.description;
        } else {
            return props.value?.description;
        }
    },
    set(newValue) {
        const businessArea = _.find(businessareasStore.businessareas, {
            description: newValue,
        }) as Businessarea;
        if (props.type === 'edit') {
            companyStore.userCompany.businessArea = businessArea;
        } else {
            emit('select', businessArea);
        }
    },
});

watch(
    () => selectedType.value,
    (value) => {
        const type = types.find((el) => (el as any).label === value);
        if (type) {
            businessareasStore.resetFiltered(type.value);
            if (props.type === 'edit') {
                companyStore.userCompany.businessArea = null;
            } else {
                emit('select', null);
            }
        }
    }
);

watch(search, (value) => {
    const type = types.find((el) => (el as any).label === selectedType?.value);
    if (_.isString(value) && value.length > 2) {
        businessareasStore.filter(value, type?.value);
    } else {
        businessareasStore.resetFiltered(type?.value);
    }
});

getBusinessAreas();
</script>
