<template>
    <div>
        <div
            style="height: 75px"
            :class="`d-flex align-center justify-space-between ${
                display.xs.value ? 'px-4' : 'px-16'
            }`"
        >
            <div class="cursor" @click="goToHome">
                <v-img
                    :height="logoSize"
                    :width="logoSize"
                    class="my-auto"
                    :src="require(`@/assets/images/${logo}`)"
                ></v-img>
            </div>
            <div class="d-flex">
                <language-menu :theme="getThemeColor()" :showTitle="true" />
                <v-btn
                    class="font ml-6"
                    flat
                    :variant="userStore.isLoggedIn ? 'text' : 'flat'"
                    rounded="pill"
                    :color="userStore.isLoggedIn ? 'title' : 'white'"
                    @click="handleButtonClick"
                >
                    {{ userStore.isLoggedIn ? $t('exit') : $t('sign_in') }}
                </v-btn>
            </div>
        </div>
        <v-divider />
        <who-are-you-dialog />
    </div>
</template>

<script lang="ts" setup>
import { signoutUser } from '@/composables/auth';
import { RoutesNames } from '@/router/routesNames';
import { useUserStore } from '@/store/user';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import WhoAreYouDialog from '../onboarding/WhoAreYouDialog.vue';
import LanguageMenu from './LanguageMenu.vue';
import { isSmallDevice } from '@/utils/helpers';
import { useDisplay } from 'vuetify';
import { checkViewType } from '@/composables/auth';

const route = useRoute();
const router = useRouter();

const userStore = useUserStore();
const display = useDisplay();

const logo = ref('logo-color.svg');
const logoSize = ref('40');

if (isSmallDevice()) {
    logoSize.value = '40';
} else {
    if (
        route.name == RoutesNames.onboarding_step_1 ||
        route.name == RoutesNames.onboarding_step_2
    ) {
        logo.value = 'logo-color.svg';
        logoSize.value = '40';
    } else {
        logo.value = 'logo-color.svg';
        logoSize.value = '60';
    }
}

function handleButtonClick() {
    if (userStore.isLoggedIn) {
        signoutUser();
    } else {
        router.push({ name: RoutesNames.signIn });
    }
}

function getThemeColor() {
    return route.name !== RoutesNames.onboarding_start ? 'white' : 'transparent';
}

function goToHome() {
    // router.push({ name: RoutesNames.onboarding_start });
    checkViewType();
}
</script>
