<template>
    <v-dialog class="font" v-model="dialogsStore.terms" persistent>
        <v-sheet width="600" heigth="80vh" class="pa-6 rounded-xl">
            <v-row no-gutters justify="center" align-items="center">
                <v-col class="px-2">
                    <div>
                        <v-img
                            height="70"
                            width="70"
                            class="mx-auto"
                            src="@/assets/images/logo-color.svg"
                        ></v-img>
                        <div class="text-h6 font-weight-medium text-center">
                            {{ $t('welcome_to_wh') }}
                        </div>
                        <div class="text-subtitle-2 text-highlight mt-2 text-center">
                            {{ $t('simple_rules') }}
                        </div>
                        <div
                            class="modal-text-content mt-6"
                            style="height: 35vh; overflow-y: auto"
                            v-on:scroll.passive="checkScroll"
                            v-if="!loading"
                        >
                            <div class="text-body-2" v-html="terms?.content" />
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <v-btn icon color="white" @click="scrollTo" size="small">
                                <v-icon color="primary">mdi-chevron-down</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center">
                            <v-btn
                                rounded="pill"
                                :disabled="!canAccept"
                                class="text-uppercase mt-6"
                                color="primary"
                                flat
                                @click="handleAcceptClick"
                            >
                                {{ $t('accept') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import {
    useAcceptTermAndCondition,
    useUpdateUser,
    useGetTerms,
} from '@/composables/user';
import { OnBoardingUser } from '@/graphql/types/graphql';
import { useDialogsStore } from '@/store/dialogs';
import { useUserStore } from '@/store/user';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { TermConditionType } from '@/graphql/types/graphql';

const dialogsStore = useDialogsStore();
const userStore = useUserStore();
const i18n = useI18n();
const language = ref(i18n.locale.value == 'it' ? 'ita' : 'eng');
const terms = ref(null);
const loading = ref(false);
const canAccept = ref(false);

watch(i18n.locale, () => {
    language.value = i18n.locale.value == 'it' ? 'ita' : 'eng';
    getTerm();
});

const {
    mutate: acceptTerms,
    loading: loadingAccept,
    onDone: onDoneAccept,
} = useAcceptTermAndCondition();

function getTerm() {
    const {
        result,
        loading: loadingGet,
        onError,
    } = useGetTerms(language, TermConditionType.User);
    watch(result, (data) => {
        if (data?.TermConditionLast) {
            terms.value = data?.TermConditionLast;
        }
    });

    watch(loadingGet, () => {
        loading.value = loadingGet.value;
    });
}

watch(
    () => dialogsStore.terms,
    () => {
        if (dialogsStore.terms) {
            getTerm();
        }
    }
);

const {
    mutate: updateUser,
    loading: loadingUpdate,
    onDone: onDoneUpdate,
} = useUpdateUser();

onDoneUpdate((result) => {
    dialogsStore.terms = false;
});

function scrollTo() {
    const element = document.getElementsByClassName('modal-text-content')[0];
    if (element) {
        element.scrollTop = element.scrollHeight;
        canAccept.value = true;
    }
}

function checkScroll() {
    const element = document.getElementsByClassName('modal-text-content')[0];
    if (element && element.scrollHeight - element.clientHeight === element.scrollTop) {
        canAccept.value = true;
    }
}

async function handleAcceptClick() {
    await acceptTerms({
        termConditionId: 'cb7b223b-0005-4635-a610-97f4d9534fe1',
    });

    //Prossimo step dopo terms è userType per mantenere congruità con l'applicazione
    await updateUser({
        id: userStore.getUserId,
        onboarding_user_step: OnBoardingUser.UserType,
    });
}
</script>
