<template>
    <v-row v-if="!closed" class="upload-box">
        <v-col cols="12">
            <div class="upload-box-header d-flex justify-end align-center">
                <v-icon @click="reduce" class="mr-2" size="large" color="white">
                    {{ cvStore.collapsed ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
                <v-icon @click="close" class="mr-4" color="white">mdi-close</v-icon>
            </div>
            <div v-if="!cvStore.collapsed" class="bg-white pb-5">
                <div class="pa-4">
                    <div class="uploading-item mb-4">
                        <v-row>
                            <v-col cols="10">
                                <h5 class="text-info">
                                    {{
                                        !cvStore.uploadFinished
                                            ? $t('uploading')
                                            : $t('upload_completed')
                                    }}
                                </h5>
                                <p class="text-skillsText mb-2">
                                    {{ cvStore.percentage.toFixed(2) }}%
                                    {{
                                        cvStore.time > 0
                                            ? ' | ' + cvStore.time.toFixed(0)
                                            : ''
                                    }}
                                    {{ ' ' }}
                                    {{ cvStore.time > 0 ? $t('seconds') : '' }}
                                    {{ ' ' }}
                                    {{ cvStore.time > 0 ? $t('to_finish') : '' }}
                                </p>
                                <v-progress-linear
                                    color="info"
                                    v-model="cvStore.percentage"
                                    :buffer-value="100"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="2" v-if="!cvStore.uploadFinished">
                                <div class="d-flex">
                                    <v-btn
                                        v-if="!cvStore.stopUpload"
                                        icon
                                        color="pauseButton"
                                        class="mr-2"
                                        elevation="0"
                                        size="x-small"
                                        @click="stopUpload"
                                    >
                                        <v-icon color="pauseIcon" size="small">
                                            mdi-pause
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        icon
                                        color="pauseButton"
                                        class="mr-2"
                                        elevation="0"
                                        size="x-small"
                                        @click="playUpload"
                                    >
                                        <v-icon color="pauseIcon" size="small">
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        size="x-small"
                                        elevation="0"
                                        color="cancelButton"
                                        @click="undoUpload"
                                    >
                                        <v-icon color="red" size="small">
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="2" v-else>
                                <div
                                    class="d-flex flex-column align-end fill-height justify-center"
                                >
                                    <v-btn
                                        icon
                                        size="x-small"
                                        elevation="0"
                                        color="cancelButton"
                                    >
                                        <v-icon color="primary" size="small">
                                            mdi-check
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <v-divider />
                <div class="upload-errors px-4 py-2">
                    <div
                        :key="j"
                        class="d-flex align-center justify-space-between"
                        v-for="(file, j) in erroredFiles"
                    >
                        <div class="d-flex align-center">
                            <v-icon class="mr-2" color="red" size="large">
                                mdi-file-document-outline
                            </v-icon>
                            <p class="text-caption text-skillsText">
                                {{ file.fileName }}
                            </p>
                        </div>
                        <v-icon class="ml-2" color="red" size="large">mdi-alert</v-icon>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useCvUploadStore } from '@/store/cvUploads';

const closed = ref(false);
const cvStore = useCvUploadStore();

const erroredFiles = computed({
    get() {
        return cvStore.erroredFiles;
    },
    set() {
        return;
    },
});

function reduce() {
    cvStore.collapsed = !cvStore.collapsed;
}

function close() {
    closed.value = true;
}

function stopUpload() {
    cvStore.stopUpload = true;
}

function undoUpload() {
    cvStore.undoUpload = true;
    cvStore.cancelUpload();
}

function playUpload() {
    cvStore.uploadCv();
}
</script>
<style scoped>
.upload-box {
    bottom: 0;
    right: 20px;
    width: 550px;
    z-index: 9999;
    position: absolute;
}
.upload-box-header {
    height: 55px;
    border-radius: 15px 15px 0px 0px;
    background: transparent linear-gradient(90deg, #003e7e 0%, #823f8d 100%) 0% 0%
        no-repeat padding-box;
}
.uploading-item {
    opacity: 0.7;
    border-radius: 16px;
    padding: 20px 30px;
    background: #eaedf283;
}
.uploading-item h5 {
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    font-family: Poppins;
    letter-spacing: 0px;
}
.uploading-item p {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    font-family: Poppins;
    letter-spacing: 0px;
}
.upload-errors {
    max-height: 150px;
    overflow-y: scroll;
}
</style>
