const en = {
    step: 'step',
    create_password_to_start: 'Create a password to start the journey',
    yes_subscribe_to_newsletter: 'Yes, I want to subscribe to the newsletter',
    minimum_password_characters: 'Minimum {passwordLength} characters',
    welcome_to_wh: 'Welcome you to Wh!',
    simple_rules: 'Here are some simple rules to keep in mind.',
    general_conditions: 'General conditions',
    who_are_you: 'Who are you?',
    i_am_company: 'I am a company',
    are_you_company_not_a_talent: 'Are you a Company (Are you not a talent)?',
    are_you_talent_not_a_company: 'Are you a Talent (Not a company)?',
    find_out_more: 'Find out more',
    click_here: 'Click here',
    and_i_search_talents: "and I'm looking for talents",
    i_am_talent: 'I am a talent',
    and_i_search_job: "and I'm looking for a job",
    my_name_is: 'My name is',
    my_birthday: 'My date of birth',
    birthday: 'Date of birth',
    your_age_will_be_public: 'Your age will be public',
    where_i_am: 'Where am I',
    city_residence: 'City√† of residence',
    your_residence_will_be_public: 'Your residence will be public',
    i_d_like: 'I would like to',
    work_from_home: 'Work from home',
    work_from_my_city: 'Work from my city√†',
    work_from_another_city: 'Work from another city',
    work_from_another_country: 'Working abroad',
    show_preference_on_profile: 'Show preference on profile',
    add_city: 'Add city√†',
    can_add_max_cities: 'You can add up to a maximum of {maxCities} cities√†',
    city_will_be_shown: 'The city you would like to work in will appear on wh!',
    second_city: 'Second city√†',
    third_city: 'Third city√†',
    add_nation: 'Add country',
    can_add_max_nations: 'You can add up to a maximum of {maxNations} nations',
    nation_will_be_shown: 'The country you would like to work in will appear on wh!',
    second_nation: 'Second nation',
    third_nation: 'Third nation',
    i_am: 'I am',
    professional_qualification: 'Professional Qualification',
    professional_qualification_will_be_public:
        'The professional qualification will be public',
    experience: 'Experience',
    define_myself: 'I define myself',
    select_defects: 'Select up to {maxDefects} defects',
    select_merits: 'Select up to {maxMerits} merits',
    show_aptitudes_on_profile: 'Show aptitudes on profile',
    start_now: 'Start now',
    create_account_with_github: 'Create account with GitHub',
    create_account_with_google: 'Continue with Google',
    create_account_with_linkedin: 'Create account with Linkedin',
    create_account_with_apple: 'Create account with Apple',
    signin_with_github: 'Sign in with GitHub',
    signin_with_google: 'Sign in with Google',
    signin_with_linkedin: 'Sign in with Linkedin',
    signin_with_apple: 'Sign in with Apple',
    signin_with_email: 'Sign in with email',
    mandatory_step: 'Mandatory (step {currentOnboardingStep} of {maxOnboardingStep})',
    i_introduce_myself: 'Let me introduce myself',
    add_merits: 'Add merits',
    add_defects: 'Add defects',
    save_merits: 'Save merits',
    save_defects: 'Save defects',
    create_account_with_email: 'Create account with email',
    show_opportunities: 'Show me opportunities',
    opportunities_less_than_1_year: 'Less than 1 year',
    opportunities_more_than_1_year: 'More than 1 year',
    opportunities_recruitments: 'Hiring',
    opportunities_freelance_and_vat_number: 'Freelance and VAT number',
    url_video_cv: 'CV Video URL',
    privacy_title: 'Your privacy is important to Wh!',
    privacy_subtitle:
        "We and our partners use trackers to process data about our app users, personalize the ads you see on Wh and elsewhere, improve Wh's marketing operations and enable the use of social features.",
    personalize: 'Personalise',
    privacy_text:
        'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not.',
    handle_permissions: 'Manage permissions',
    ads_auth: 'Provide Advertising Permissions',
    ads_auth_subtitle: 'I do not provide Advertising Permissions.',
    personalize_ads_auth: 'Customize Ad Permissions',
    marketing_auth: 'Provide Marketing Permissions',
    marketing_auth_subtitle: 'I do not provide Marketing Permissions.',
    personalize_marketing_auth: 'Customize Marketing Permissions',
    consent_all: 'Allow all',
    reject_all: 'Reject all',
    signup_disclaimer_1: 'By selecting Create Account, you confirm that you accept our ',
    signup_disclaimer_2: ' Find out how we process your data in our ',
    privacyPolicyCookiePolicy: 'Privacy Policy and Cookie Policy.',
    terms: 'Terms.',
    already_account_signin: 'If you already have an account click here to sign in',
    already_account: 'Do you already have an account?',
    click_here_to_signin: 'click here to sign in',
    first_time_on_wh: 'First time on Whp.ai?',
    click_here_to_sign_up: 'click here to sign up',
    first_time_sign_up: 'First time on Whp.ai? Click here to sign up',
};
export default en;
