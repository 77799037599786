import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'v-network-graph/lib/style.css';
import 'vue3-easy-data-table/dist/style.css';
import './styles/css/font.css';
import './styles/css/main.css';
import '@vuepic/vue-datepicker/dist/main.css';

import { createApp, h, provide } from 'vue';

import App from './App.vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VNetworkGraph from 'v-network-graph';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueGuidedTour from 'vue-guided-tour';
import VueSocialSharing from 'vue-social-sharing';
import { apolloClient } from './graphql/apollo';
import { createGtm } from '@gtm-support/vue-gtm';
import i18n from './i18n';
import { initFirebase } from './plugins/firebase';
import initMoment from './plugins/moment';
import { loadConfig } from '@/utils/tagmanager';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

initFirebase();
loadFonts();
initMoment();

//https://v4.apollo.vuejs.org/guide-composable/setup.html#vue-3

const app = createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
});

const tagConfig = loadConfig(router);

app.use(router);
app.use(store);
app.use(i18n);
app.use(vuetify);
app.use(VueGuidedTour);
app.use(VNetworkGraph);
app.use(VueSocialSharing);
app.use(createGtm(tagConfig));
app.component('EasyDataTable', Vue3EasyDataTable);
app.provide('vtg', app.config.globalProperties.$vtg);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');
