import { PersonalityListDocument } from '@/graphql/types/graphql';
import query from '@/graphql/query';

export enum PersonalityType {
    merit = 'merit',
    defect = 'defect',
}

export function useGetPersonalities(personalityType: PersonalityType) {
    const { result, loading, onError } = query(PersonalityListDocument, {
        personalitySection: personalityType,
        language_id: 'ita',
    });
    return { result, loading, onError };
}
