<template>
    <v-dialog class="font" v-model="dialogsStore.addCompany" @click:outside="closeDialog">
        <v-sheet width="550" class="pb-6 pt-1 px-1 rounded-xl">
            <v-row no-gutters justify="end" align-items="center">
                <v-btn
                    @click="closeDialog"
                    style="float: right; right: 0px"
                    fab
                    absolute
                    icon
                    color="white"
                    variant="text"
                    class=""
                >
                    <v-icon color="secondary">mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-row
                no-gutters
                justify="center"
                align-items="center"
                class="px-6"
                v-if="!showResult"
            >
                <v-col class="px-2">
                    <div>
                        <div class="text-h5 text-center mb-5">
                            {{ $t('add_company') }}
                        </div>
                        <company-selector
                            :value="selectedCompany"
                            @select="selectCompany"
                            @write="writeCompany"
                            @selectRole="selectRole"
                            :isOnboarding="false"
                        />
                        <v-text-field
                            v-model="vat"
                            v-if="!selectedCompany || !selectedCompany.id"
                            class="mt-6"
                            :type="'text'"
                            color="primary"
                            :label="$t('vat')"
                            variant="underlined"
                            placeholder="IT12345678901"
                            :rules="[(v) => !!isEuVatNumber(v) || $t('invalid_vat')]"
                        ></v-text-field>
                        <businessarea-selector
                            v-if="!selectedCompany || !selectedCompany.id"
                            type="add"
                            @select="chooseBusinessArea"
                            :value="selectedBusinessArea"
                            class="mt-2"
                        ></businessarea-selector>
                        <div>
                            <div class="font-weight-bold text-subtitle">
                                {{ $t('confirm_to_work_in_this_company') }}
                            </div>
                            <div class="text-body-2 text-subtitle">
                                {{ $t('add_company_after_validation') }}
                            </div>
                            <v-radio-group v-model="checked" inline hide-details>
                                <v-radio
                                    value="yes"
                                    color="primary"
                                    label="Si, lavoro per questa azienda"
                                    class="radio-text"
                                />
                            </v-radio-group>
                        </div>
                        <v-radio-group
                            v-model="checkedTerms"
                            inline
                            class="mt-4"
                            hide-details
                            v-if="!selectedCompany || !selectedCompany.id"
                        >
                            <v-radio value="yes" color="primary" class="radio-text">
                                <template v-slot:label>
                                    {{ $t('accept') }}
                                    <div
                                        @click="goTo(RoutesNames.terms_conditions, true)"
                                        class="text-primary cursor"
                                    >
                                        {{ $t('terms_and_conditions') }}
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <v-btn
                            rounded="pill"
                            class="text-uppercase mt-2"
                            color="primary"
                            flat
                            block
                            :disabled="!canAdd()"
                            @click="saveCompany"
                        >
                            {{ $t('save') }}
                        </v-btn>
                        <div class="mt-4 mb-4 text-subtitle-2 text-subtitle text-center">
                            <p class="">{{ $t('required_field') }}</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                justify="center"
                align-items="center"
                class="px-6 text-center"
                v-if="showResult"
            >
                <v-avatar color="gray" size="100">
                    <v-icon color="lightGray" size="50">mdi-alarm</v-icon>
                </v-avatar>
                <div class="text-body-1 font-weight-bold mt-10">
                    {{ getResultTitle() }}
                </div>
                <div class="text-subtitle">
                    {{ getResultMessage() }}
                </div>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { isEuVatNumber } from '@/utils/helpers';
import { useDialogsStore } from '@/store/dialogs';
import { useCompanyStore } from '@/store/companies';
import { ref, defineEmits } from 'vue';
import BusinessareaSelector from '@/components/shared/BusinessareaSelector.vue';
import CompanySelector from '@/components/company/CompanySelector';
import { RoutesNames } from '@/router/routesNames';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();
const emit = defineEmits(['companyAdded', 'companyAddSelected']);

const selectedBusinessArea = ref(null);
const selectedCompany = ref(null);
const companyName = ref('');
const selectedRole = ref(null);
const checked = ref(null);
const vat = ref('');
const checkedTerms = ref(null);
const show_vat_error = ref(false);

const showResult = ref(false);

const companyStore = useCompanyStore();
const dialogsStore = useDialogsStore();

function closeDialog() {
    selectedBusinessArea.value = null;
    companyStore.companies = null;
    dialogsStore.addCompany = false;
    checked.value = null;
    checkedTerms.value = null;
    showResult.value = false;
    vat.value = null;
}

function getResultTitle() {
    if (!selectedCompany.value) {
        return t('we_are_verifying');
    } else {
        return t('request_sent_to_company_admin');
    }
}

function getResultMessage() {
    if (!selectedCompany.value) {
        return t('we_are_verifing_data');
    } else {
        return t('wait_for_the_company_administrator_to_approve');
    }
}

function canAdd() {
    let can = false;
    let isValidVat = true;
    if (!selectedCompany.value) {
        can =
            companyName.value !== '' &&
            selectedBusinessArea.value !== null &&
            vat.value &&
            vat.value !== '' &&
            !!isEuVatNumber(vat.value) &&
            checkedTerms.value == 'yes';
    } else {
        can = selectedRole.value !== null;
    }
    return can && checked.value == 'yes' && isValidVat;
}

function saveCompany() {
    if (!selectedCompany.value || !selectedCompany.value.id) {
        const companyToAdd = {
            name: companyName.value,
            businessAreaId: selectedBusinessArea.value.id,
            vatNumber: vat.value,
            acceptTermCondition: true,
        };
        emit('companyAdded', companyToAdd);
        selectedCompany.value = null;
        vat.value = null;
    } else {
        emit('companyAddSelected', {
            company: selectedCompany.value,
            role: selectedRole.value,
        });
        companyName.value = '';
    }
    showResult.value = true;
    closeDialog();
}

function chooseBusinessArea(area) {
    selectedBusinessArea.value = area;
}
function selectCompany(company) {
    selectedCompany.value = company;
}
function writeCompany(name) {
    companyName.value = name;
}
function selectRole(role) {
    selectedRole.value = role;
}
function goTo(r, newPage) {
    if (newPage) {
        const route = router.resolve({ name: r });
        window.open(route.href, '_blank');
    } else {
        router.replace({ name: r });
    }
}
</script>
