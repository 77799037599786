<template>
    <div>
        <v-text-field
            v-model="companyName"
            :label="`${$t('name')} *`"
            class="mt-4"
            variant="underlined"
            hide-details
            color="primary"
        ></v-text-field>
        <div
            v-if="companyStore.loading"
            style="align-items: center; display: flex; justify-content: center"
            class="mt-2"
        >
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div
            v-if="
                companyStore.companies &&
                companyStore.companies.length > 0 &&
                companyName &&
                companyName.length > 0
            "
        >
            <v-card class="pa-2 mt-4" elevation="0" variant="outlined" color="gray">
                <div class="text-body-2 text-subtitle">
                    {{ $t('other_companies_message') }}
                </div>
                <div class="ml-2">
                    <div
                        v-for="c in companyStore.companies"
                        :key="c.id"
                        class="text-primary text-body-2 mt-2"
                        @click="selectCompany(c)"
                    >
                        {{ c.name }}
                        <v-icon
                            color="primary"
                            v-if="selectedCompany && selectedCompany.id == c.id"
                        >
                            mdi-check
                        </v-icon>
                    </div>
                </div>
            </v-card>
        </div>
        <v-select
            v-if="selectedCompany"
            v-model="selectedRole"
            :items="roleList"
            class="mt-8 mb-8"
            :label="$t('role')"
            variant="underlined"
            hide-details
            color="primary"
        ></v-select>
    </div>
</template>

<script lang="ts" setup>
import configuration from '@/composables/config';
import { useSearchCompany } from '@/composables/companies';
import { useCompanyStore } from '@/store/companies';
import { useUserStore } from '@/store/user';
import { ref, watch, defineEmits, withDefaults, defineProps } from 'vue';
import _ from 'lodash';
import { Company, CompanyEmployeeRole } from '@/graphql/types/graphql';
import { useI18n } from 'vue-i18n';

interface CompanySelectorProps {
    isOnboarding: boolean;
}

const props = withDefaults(defineProps<CompanySelectorProps>(), {
    isOnboarding: false,
});

const { t } = useI18n();

let companyRoles = Object.values(CompanyEmployeeRole).filter(
    (el) => el !== CompanyEmployeeRole.Admin
);

const roles = companyRoles.map((el) => ({
    label: t((el as string).toLowerCase()),
    value: el,
}));

const roleList = roles.map((el) => el.label);

const emit = defineEmits(['select', 'write', 'selectRole']);

const companyStore = useCompanyStore();
const userStore = useUserStore();
const companyName = ref('');
const selectedCompany = ref(null);
const selectedRole = ref(null);

const userCompaniesId = userStore.allCompanies
    ? userStore.allCompanies.map((el) => el.companyId)
    : [];

watch(companyName, (value) => {
    if (_.isString(value)) {
        if (value.length >= 3) {
            searchCompany(value);
        }
    } else {
        companyStore.companies = null;
        selectCompany(null);
    }
    emit('write', value);
});

watch(selectedRole, (value) => {
    const role = roles.find((el) => (el as any).label === value);
    emit('selectRole', role.value);
});

function selectCompany(company) {
    selectedCompany.value = company;
    if (company) {
        companyName.value = company.name;
    }

    emit('select', company);
}

function searchCompany(text: string) {
    const { result, loading, onError } = useSearchCompany({ text });
    companyStore.loading = true;
    watch(result, (data) => {
        if (data && data.CompanySearch) {
            companyStore.companies = data.CompanySearch.filter(
                (c) => !userCompaniesId.includes(c.id)
            ) as Company[];
        } else {
            companyStore.companies = null;
        }
        companyStore.loading = loading.value;
    });

    onError((result) => {
        console.error(result.message);
        companyStore.loading = loading.value;
    });
}
</script>
