import {
    CompanySearchRequest,
    AddCompanyUploadCvDocument,
    AddMeToCompanyDocument,
    CandidateByJobAnnouncementIdDocument,
    ChangeUserRoleInCompanyDocument,
    Company,
    CompanyByIdDocument,
    CompanyPublicByIdDocument,
    CompanySearchCvOutput,
    CompanySearchDocument,
    CompanyUploadCvDataElaborationCategory,
    CompanyUploadCvGroupByIdDocument,
    CompanyUploadCvGroupByIdRequest,
    CompanyUploadCvGroupsByCompanyIdDocument,
    ConfirmUserToCompanyDocument,
    CreateCompanyUploadCvGroupDocument,
    CreateHiringProcessDocument,
    DatailForCompanyUploadCvDocument,
    EditCompanyUploadCvDataElaborationsDocument,
    HiringProcessByIdDocument,
    InferenceMapForCvDocument,
    InsertCompanyDocument,
    InsertSavedTalentDocument,
    Maybe,
    RefuseUserToCompanyDocument,
    RemoveSavedTalentDocument,
    RemoveUserToCompanyDocument,
    SearchCompanyCvDocument,
    SearchCompanyCvInput,
    InsertSavedSearchDocument,
    SetCandidateAsPreferredDocument,
    SearchCompanyUploadCvDocument,
    SearchCompanyUploadCvRequest,
    UpdateCompanyDocument,
    SetCandidateAsRefusedDocument,
    SetCandidateAsAcceptedDocument,
    SetManyCandidatesAsAcceptedDocument,
    AddBulkTalentsToSavedSearchDocument,
    InsertManySavedTalentsDocument,
    SearchSavedSearchesInput,
    SearchSavedSearchesDocument,
    SearchCompanyCvOrigin,
    CompaniesForUserDocument,
    CompanyEmployeeRole,
    RemoveManySavedTalentsDocument,
} from '@/graphql/types/graphql';
import { Ref, ref, watch } from 'vue';
import { getUser } from './user';
import mutation from '@/graphql/mutation';
import query from '@/graphql/query';
import { useCompanyStore } from '@/store/companies';
import { useErrorStore } from '@/store/error';
import { useSearchTalentStore } from '@/store/searchTalent';
import { useUserStore } from '@/store/user';

export function useSearchCompany(input: CompanySearchRequest) {
    const { result, loading, onError } = query(CompanySearchDocument, {
        input: input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useGetCompanyById(id: string) {
    const { result, loading, onError } = query(CompanyByIdDocument, {
        id: id,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

function handleCompany(company: Maybe<Company>) {
    const store = useCompanyStore();
    store.userCompany = company;
}

export function useUpdateCompany() {
    const { mutate, loading, onDone, onError } = mutation(UpdateCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    const result: Ref<Maybe<Company>> = ref(null);

    onDone((response) => {
        handleCompany(response?.data?.updateCompany as Maybe<Company>);
        result.value = response?.data?.updateCompany as Maybe<Company>;
    });
    return { mutate, loading, onDone, result };
}

export function useAddCompany() {
    const { mutate, loading, onDone, onError } = mutation(InsertCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    const result: Ref<Maybe<Company>> = ref(null);

    onDone((response) => {
        //getUser();
        getUserCompanies();
        result.value = response?.data?.insertCompany as Maybe<Company>;
    });
    return { mutate, loading, onDone, result };
}

export function useAddMeToCompany() {
    const { mutate, loading, onDone, onError } = mutation(AddMeToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    onDone((response) => {
        // getUser();
        getUserCompanies();
    });

    return { mutate, loading, onDone };
}

export function useAddCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(AddCompanyUploadCvDocument);

    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCreateCompanyUploadCVGroup() {
    const { mutate, loading, onDone, onError } = mutation(
        CreateCompanyUploadCvGroupDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCompanyUploadCVGroupsByCompany(
    id: string,
    offset: number,
    limit: number
) {
    const { result, loading, onError } = query(CompanyUploadCvGroupsByCompanyIdDocument, {
        input: {
            companyId: id,
            lengthGroup: limit,
            offsetGroup: offset,
        },
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useConfirmUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(ConfirmUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useRefuseUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(RefuseUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useGetCvUploadGroup(input: CompanyUploadCvGroupByIdRequest) {
    const { result, loading, onError } = query(CompanyUploadCvGroupByIdDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useChangeUserRoleInCompany() {
    const { mutate, loading, onDone, onError } = mutation(
        ChangeUserRoleInCompanyDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useRemoveUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(RemoveUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useSearchCompanyUploadCV(input: SearchCompanyUploadCvRequest) {
    const { result, loading, onError } = query(SearchCompanyUploadCvDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useSearchCompanyCV(input: SearchCompanyCvInput) {
    const searchStore = useSearchTalentStore();
    searchStore.results = [];

    const { result, loading, onError } = query(SearchCompanyCvDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useDetailForCompanyUploadCv(id: string, companyId: string) {
    const { result, loading, onError } = query(DatailForCompanyUploadCvDocument, {
        id,
        companyId,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCreateHiringProcess() {
    const { mutate, loading, onDone, onError } = mutation(CreateHiringProcessDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useInferenceMapForCV(
    id: string,
    companyId: string,
    type: CompanyUploadCvDataElaborationCategory
) {
    const { result, loading, onError } = query(InferenceMapForCvDocument, {
        id,
        companyId,
        type,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useInsertSavedTalent() {
    const { mutate, loading, onDone, onError } = mutation(InsertSavedTalentDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveSavedTalent() {
    const { mutate, loading, onDone, onError } = mutation(RemoveSavedTalentDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useHiringProcessById(id: string) {
    const { result, loading, onError } = query(HiringProcessByIdDocument, {
        id,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function doSearchTalent(searchId?: string) {
    const userStore = useUserStore();
    if (userStore.currentCompany?.companyId) {
        const searchStore = useSearchTalentStore();

        const origin = searchStore.origin;
        searchStore.loadingSearchResult = true;
        let params: SearchCompanyCvInput = {
            companyId: userStore.currentCompany?.companyId as string,
            origin,
            skills: searchStore.skills.map((s) => s.id),
            hiringType: searchStore.hiringType ? searchStore.hiringType : null,
            locationType: searchStore.locationType ? searchStore.locationType : null,
            locationCity: searchStore.city,
            qualifications: searchStore.qualification
                ? [searchStore.qualification.id]
                : [],
            limit: 100,
            orderBy: searchStore.orderBy,
        };
        if (searchId) {
            params = {
                companyId: userStore.currentCompany?.companyId as string,
                searchId: searchId,
                origin: SearchCompanyCvOrigin.All,
            };
        }
        const { result, loading } = useSearchCompanyCV(params);

        watch(loading, () => {
            searchStore.loadingSearchResult = loading.value;
        });

        watch(result, (data) => {
            searchStore.results = data?.searchCompanyCv?.data as [CompanySearchCvOutput];
        });
    }
}

export function useGetCompanyPublicById(id: string) {
    const { result, loading, onError } = query(CompanyPublicByIdDocument, {
        id: id,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCandidatesByJobAnnouncementId(
    id: string,
    length: number,
    offset: number
) {
    const { result, loading, onError } = query(CandidateByJobAnnouncementIdDocument, {
        jobAnnouncementId: id,
        length,
        offset,
    });

    return { result, loading, onError };
}

export function useEditCompanyUploadCvDataElaborations() {
    const { mutate, loading, onDone, onError } = mutation(
        EditCompanyUploadCvDataElaborationsDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function getDetail(id: string, companyId: string) {
    const searchStore = useSearchTalentStore();
    searchStore.loadingTalentDetail = true;
    searchStore.talent = null;

    const { result, onError } = useDetailForCompanyUploadCv(id, companyId);

    watch(result, (data) => {
        if (data && data.datailForCompanyUploadCv) {
            searchStore.talent = data.datailForCompanyUploadCv;
        }
        searchStore.loadingTalentDetail = false;
    });
    onError(() => {
        searchStore.loadingTalentDetail = false;
    });
}

export function useSetPreferredCandidate() {
    const { mutate, loading, onDone, onError } = mutation(
        SetCandidateAsPreferredDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetRefusedCandidate() {
    const { mutate, loading, onDone, onError } = mutation(SetCandidateAsRefusedDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetAcceptCandidate() {
    const { mutate, loading, onDone, onError } = mutation(SetCandidateAsAcceptedDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetManyCandidatesAsAccepted() {
    const { mutate, loading, onDone, onError } = mutation(
        SetManyCandidatesAsAcceptedDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useAddBulkTalentsToSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        AddBulkTalentsToSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useInsertManySavedTalents() {
    const { mutate, loading, onDone, onError } = mutation(InsertManySavedTalentsDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveManySavedTalents() {
    const { mutate, loading, onDone, onError } = mutation(RemoveManySavedTalentsDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSaveSearchTalent() {
    const { mutate, loading, onDone, onError } = mutation(InsertSavedSearchDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSearchSavedSearches(input: SearchSavedSearchesInput) {
    const { result, loading, onError } = query(SearchSavedSearchesDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCompaniesForUser() {
    const { result, loading, onError } = query(CompaniesForUserDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function getUserCompanies() {
    const userStore = useUserStore();

    userStore.fetching = true;

    const { result } = useCompaniesForUser();

    watch(result, (data) => {
        if (data?.CompaniesForUser) {
            userStore.allUserCompanies = data.CompaniesForUser;
            const reorganizedCompanies = data.CompaniesForUser.map((c) => {
                const employee =
                    c && c.employees && c.employees.length > 0
                        ? c.employees.find((el) => el?.user?.id === userStore.user?.id)
                        : null;

                const data = {
                    companyId: c!.id,
                    companyName: c!.name,
                    company_approval_date: c!.isApproved ? 'true' : null,
                    id: null,
                    role: employee ? (employee.role as CompanyEmployeeRole) : null,
                    user_approval_date:
                        employee && employee.state === 'CONFIRMED' ? 'true' : null,
                };
                return data;
            });
            userStore.companies = reorganizedCompanies;
            userStore.resetFilteredCompanies();
        }

        userStore.fetching = false;
    });
}
