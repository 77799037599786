import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { hideCustomerly, showCustomerly } from '@/plugins/customerly';

import AppNavbar from '@/components/structure/AppNavbar.vue';
import AppNavigation from '@/components/structure/AppNavigation.vue';
import { RoutesNames } from './routesNames';
import SignupNavbar from '@/components/structure/SignupNavbar.vue';
import { getCurrentSession } from '@/composables/auth';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: RoutesNames.root,
        redirect: { name: RoutesNames.onboarding_start },
    },
    {
        path: '/help/privacy-policy',
        name: RoutesNames.privacy_cookie,
        meta: { public: true, title: 'privacy Policy' },
        components: {
            default: () => import('../views/help/PrivacyCookiePolicy.vue'),
        },
    },
    {
        path: '/help',
        name: RoutesNames.help,
        meta: { public: true, title: 'Contacts' },
        components: {
            default: () => import('../views/help/ContactPage.vue'),
        },
    },
    {
        path: '/help/terms-and-conditions',
        name: RoutesNames.terms_conditions,
        meta: { public: true, title: 'Terms ad conditions' },
        components: {
            default: () => import('../views/help/TermsAndConditions.vue'),
        },
    },
    {
        path: '/help/terms-and-conditions-of-services',
        name: RoutesNames.terms_services,
        meta: { public: true, title: 'Terms ad conditions of services' },
        components: {
            default: () => import('../views/help/TermsOfServices.vue'),
        },
    },
    {
        path: '/signup',
        component: () => import('../views/signup/SignupContainerView.vue'),
        children: [
            {
                path: '',
                name: RoutesNames.onboarding_start,
                component: () => import('../views/signup/SignUpView.vue'),
                meta: { public: true, title: 'Signup' },
            },
            {
                path: 'talent',
                name: RoutesNames.signup_talent,
                component: () => import('../views/signup/SignUpView.vue'),
                meta: { public: true, title: 'Signup Talent' },
            },
            {
                path: 'password',
                name: RoutesNames.onboarding_setPassword,
                component: () => import('../views/signup/SetPasswordView.vue'),
                meta: { public: true, title: 'Change Password' },
            },
            {
                path: 'stepone',
                name: RoutesNames.onboarding_step_1,
                component: () => import('../views/signup/OnboardingStep1View.vue'),
                meta: { title: 'Onboarding step 1' },
            },
            {
                path: 'steptwo',
                name: RoutesNames.onboarding_step_2,
                component: () => import('../views/signup/OnboardingStep2View.vue'),
                meta: { title: 'Onboarding step 2' },
            },
        ],
    },
    {
        path: '/subscriptions',
        name: RoutesNames.subscription,
        components: {
            default: () => import('../views/ChooseSubscription.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Subscription', company: true },
    },
    {
        path: '/dashboard',
        components: {
            default: () => import('../views/user/dashboard/DashboardContainer.vue'),
        },
        children: [
            {
                path: '',
                name: RoutesNames.dashboard,
                component: () => import('../views/user/dashboard/CoursesView.vue'),
                meta: { title: 'User Courses Dashboard', user: true },
            },
            {
                path: 'enrollment',
                name: RoutesNames.dashboard_enrollment,
                component: () => import('../views/user/dashboard/EnrollmentView.vue'),
                meta: { title: 'Course Enrollment', user: true },
            },
            {
                path: 'next-lessons',
                name: RoutesNames.dashboard_nextLessons,
                component: () => import('../views/user/dashboard/NextLessonsView.vue'),
                meta: { title: 'Next courses lessons', user: true },
            },
            {
                path: 'course/:id?',
                name: RoutesNames.dashboard_course_details,
                component: () =>
                    import(
                        '../views/company/dashboard/courses/detail/CourseInformation.vue'
                    ),
                meta: { title: 'Course Detail', user: true },
            },
        ],
    },
    {
        path: '/company/dashboard',
        name: RoutesNames.company_dashboard,
        components: {
            default: () =>
                import('../views/company/dashboard/EducationDashboardView.vue'),
        },
        meta: { title: 'Dashboard Training', company: true },
    },
    {
        path: '/company',
        components: {
            default: () => import('../views/company/DashboardContainer.vue'),
            top: AppNavbar,
        },
        children: [
            {
                path: 'courses/list',
                name: RoutesNames.company_dashboard_courses_list,
                components: {
                    default: () => import('../views/company/dashboard/CoursesList.vue'),
                },
                meta: { title: 'Company Courses List', company: true },
            },
            {
                path: 'courses/trainers',
                name: RoutesNames.company_dashboard_courses_trainers,
                components: {
                    default: () => import('../views/company/dashboard/TrainersList.vue'),
                },
                meta: { title: 'Company Trainer List', company: true },
            },
            {
                path: 'courses',
                components: {
                    default: () =>
                        import('../views/company/dashboard/DashboardCourses.vue'),
                },
                children: [
                    {
                        path: '',
                        name: RoutesNames.company_dashboard_courses,
                        components: {
                            default: () =>
                                import(
                                    '../views/company/dashboard/courses/CoursesContainer.vue'
                                ),
                        },
                        children: [
                            {
                                path: 'create/:id?',
                                name: RoutesNames.company_dashboard_courses_create,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/courses/create/CreateCourse.vue'
                                    ),
                                meta: { title: 'Company Create Course', company: true },
                            },
                            {
                                path: 'configure/:id?',
                                name: RoutesNames.company_dashboard_courses_create_step_1,
                                meta: { title: 'Create Course step 1', company: true },
                                component: () =>
                                    import(
                                        '../views/company/dashboard/courses/create/Step1ConfigureCourse.vue'
                                    ),
                            },
                            {
                                path: 'informations/:id?',
                                meta: { title: 'Create Course step 2', company: true },
                                name: RoutesNames.company_dashboard_courses_create_step_2,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/courses/create/Step2CourseInformation.vue'
                                    ),
                            },
                            {
                                path: 'details/:id?',
                                meta: { title: 'Create Course step 3', company: true },
                                name: RoutesNames.company_dashboard_courses_create_step_3,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/courses/create/Step3CourseSummary.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        path: '/list',
                        components: {
                            default: () =>
                                import(
                                    '../views/company/dashboard/courses/CoursesContainer.vue'
                                ),
                        },
                        children: [
                            {
                                path: 'detail/:id?',
                                name: RoutesNames.company_dashboard_courses_detail,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/courses/detail/CourseInformation.vue'
                                    ),
                                meta: { title: 'Company Course Detail', company: true },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/company/profile/edit',
        meta: { title: 'Edit company profile', company: true },
        name: RoutesNames.edit_company_profile,
        components: {
            default: () => import('../views/company/EditCompanyProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile',
        meta: { title: 'Profile' },
        name: RoutesNames.user_profile,
        components: {
            default: () => import('../views/user/UserProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/settings',
        meta: { title: 'Settings' },
        name: RoutesNames.profile_settings,
        components: {
            default: () => import('../views/user/ProfileSettings.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/edit',
        meta: { title: 'Edit user profile' },
        name: RoutesNames.edit_user_profile,
        components: {
            default: () => import('../views/user/EditUserProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/cv/:id',
        name: RoutesNames.edit_user_cv,
        components: {
            default: () => import('../views/user/UserCV.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/privacy',
        name: RoutesNames.privacy,
        meta: { title: 'Accept Privacy Policy' },
        components: {
            default: () => import('../views/signup/PrivacyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/privacy/personalize',
        name: RoutesNames.personalize_privacy,
        meta: { title: 'Privacy Policy' },
        components: {
            default: () => import('../views/signup/PersonalizePrivacyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/terms',
        name: RoutesNames.company_terms,
        meta: { title: 'Company Terms and Conditions', company: true },
        components: {
            default: () => import('../views/EmptyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/confirm/email',
        name: RoutesNames.confirmEmail,
        meta: { public: true, title: 'Confirm Email' },
        components: {
            default: () => import('../views/signup/ConfirmEmail.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/userconfirm/:registrationCode',
        name: RoutesNames.userconfirm,
        meta: { public: true, title: 'User confirm' },
        components: {
            default: () => import('../views/signup/ConfirmEmail.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/reset-password',
        name: RoutesNames.resetPassword,
        components: {
            default: () => import('../views/signup/ResetPasswordView.vue'),
            top: SignupNavbar,
        },
        meta: { public: true, title: 'Reset password' },
    },
    {
        path: '/signin',
        name: RoutesNames.signIn,
        components: {
            default: () => import('../views/signup/SignInView.vue'),
            top: SignupNavbar,
        },
        meta: { public: true, title: 'Signin' },
    },
    {
        path: '/manteinance',
        name: RoutesNames.manteinance,
        meta: { public: true, title: 'Website in manteinance' },
        components: {
            default: () => import('../views/ManteinanceView.vue'),
            top: SignupNavbar,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: RoutesNames.not_found,
        meta: { public: true, title: '404 Not found' },
        components: {
            default: () => import('../views/404View.vue'),
            top: SignupNavbar,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = (to.meta.title as string) || 'WHPlatform';

    const tokenExists = (await getCurrentSession()) != undefined;
    const userType = localStorage.getItem('userType');

    //showCustomerly();

    if (!to.name) {
        return next({ name: RoutesNames.onboarding_start });
    }

    if (!Object.keys(RoutesNames).includes(String(to.name))) {
        next({ name: RoutesNames.not_found });
    }

    if (to.meta?.public) {
        // routes for not authenticated users
        if (to.name === RoutesNames.onboarding_start) {
            if (tokenExists && userType) {
                if (userType === 'user') {
                    next({ name: RoutesNames.dashboard });
                } else {
                    next({ name: RoutesNames.company_dashboard });
                }
            } else {
                next();
            }
        } else {
            next();
        }
    } else {
        // routes for only authenticated users
        if (tokenExists) {
            if (to.meta?.company && userType === 'user') {
                next({ name: RoutesNames.dashboard });
            } else if (to.meta?.user && userType === 'company') {
                next({ name: RoutesNames.company_dashboard });
            } else {
                next();
            }
        } else {
            next({ name: RoutesNames.signIn });
        }
    }
});

export default router;
