import { createI18n } from 'vue-i18n';
import en from './en/main';
import enCompanies from './en/companies';
import enErrors from './en/errors';
import enHometour from './en/hometour';
import enOnboarding from './en/onboarding';
import enPassword from './en/password';
import enUser from './en/user';
import enEducation from './en/education';

import it from './it/main';
import itCompanies from './it/companies';
import itErrors from './it/errors';
import itHometour from './it/hometour';
import itOnboarding from './it/onboarding';
import itPassword from './it/password';
import itUser from './it/user';
import itEducation from './it/education';

const ita = {
    ...it,
    ...itOnboarding,
    ...itErrors,
    ...itCompanies,
    ...itHometour,
    ...itUser,
    ...itPassword,
    ...itEducation,
};
const eng = {
    ...en,
    ...enOnboarding,
    ...enErrors,
    ...enCompanies,
    ...enHometour,
    ...enUser,
    ...enPassword,
    ...enEducation,
};

const messages = {
    it: ita,
    en: eng,
};

export default createI18n({
    locale: 'it', // set locale
    messages, // set locale messages
});
