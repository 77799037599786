const en = {
    add_company: 'Add company',
    businessarea: 'Sector',
    choose_social: 'Choose social',
    company: 'Company',
    choose_company: 'Choose company',
    manage_company: 'Manage company',
    change_company: 'Change company',
    cover_photo: 'Cover photo',
    add_cover_photo: 'Add cover photo',
    image_recommended: 'Image strongly recommended',
    company_headquarters: 'Company headquarters',
    headquarters: 'Headquarters',
    social_network: 'Social Network',
    services_employer: 'Employee Services',
    areas_expertise: 'Areas of expertise',
    look_for_tech_and_creative_talents: 'Look for tech and creative talents',
    search_company: 'Insert Company',
    admin: 'Administrator',
    employee: 'Employee',
    human_resource: 'Human Resources',
    trainer: 'Trainer',
    talents: 'Talents',
    offers: 'Offers',
    team: 'Team',
    delivery: 'Delivery',
    no_requests_for_your_company: 'there are no new requests for your company',
    add_company_message:
        'The business manager will receive a match notification and will be able to approve or reject it',
    upload_title: 'Drag CVs here or',
    upload_title_part_2: 'browse',
    upload_allowed_formats: 'Supported formats: PDF, DOC, DOCX',
    start_upload: 'Start uploading',
    upload_search: 'Search file name',
    start_date: 'Start date',
    end_date: 'End date',
    uploading: 'Loading...',
    upload_completed: 'Upload completed',
    to_finish: 'when finished',
    upload_of: 'Uploading',
    processed: 'Completed',
    state: 'State',
    errors: 'Errors',
    file_added_message: 'You have uploaded',
    file_added_message_1: 'curriculum, start loading now',
    working_in: 'Working in',
    file_required: 'At least one file must be uploaded to begin uploading',
    employer_requests: 'Requests',
    employer_list: 'Employee list',
    request_to_approve: 'requests to approve',
    change_role: 'Change role',
    remove_employer: 'Forget user',
    assign_role: 'Assign role',
    company_profile: 'Company profile',
    company_settings: 'Company settings',
    open_hr: 'Open HR',
    vat: 'VAT number',
    status: 'Status',
    date: 'Date',
    actions: 'Actions',
    who: 'Who',
    what: 'What',
    where: 'Where',
    how: 'How',
    remote_or_on_site: 'Hybrid, remote or on-site?',
    opportunity_type: 'Opportunity type',
    recruit_talent_cta: 'Do you want to contact the selected candidates for recruiting?',
    confirm: 'Confirm',
    talent_result_message:
        'You found {yourDb} talents in your database and {ourDb} talents in the WH database',
    profile_saved_by: 'Profile saved by {count} companies',
    order_by: 'Order by',
    active: 'Active',
    passive: 'Passive',
    activate_openhr: 'Activate Open HR',
    activate_service: 'Activate the service',
    connect_with_providers: 'Connect the providers you work with',
    extend_talent_search: 'Extend talent searches',
    passage: 'Passage {step} of {total}',
    openhr_activation: 'Open HR Activation',
    confirm_company_data: 'Confirm your company data',
    confirm_company_data_subtitle:
        'Look at your company data and make sure it is correct',
    company_name: 'Company name',
    registered_office: 'Registered office',
    sector: 'Sector',
    your_confirmation: 'Your confirmations',
    profiling: 'Profiling Open Wh!',
    profiling_subtitle:
        'You consent to the profiling processing of your personal and particular data, acquired Wh! in the context of the Open HR service, together with other data, to deduce products/services of potential interest to you as well as your preferences, tastes and behaviour?',
    do_not_accept: 'I do not accept',
    openhr_service: 'OpenHR Service',
    acknowledgment: 'Acknowledged',
    contract_cta:
        'To sign the contract enter your personal password Wh! and the confirmation code you received to your verified email',
    openhr_profiling: 'Open HR Profiling',
    openhr_privacy: 'Privacy consent',
    confirmation_code: 'Confirmation code',
    restore_code: 'Recover code',
    openhr_search:
        'Enter the VAT number preceded by the country code (e.g. IT12345678910)',
    db_visibility: 'Grant visibility of my talent database externally',
    db_visibility_subtitle:
        'We remind you that only visibility of the macrodata in your database will be granted',
    all_db_visible: 'Visibility of entire database',
    rejected_profiles: 'Visibility only rejected profiles',
    professionals: 'professionals',
    connect: 'Connect',
    best_plan_for_your: 'What is the best plan for you?',
    free_plan: 'Take a free trial. No credit card needed.',
    select_later: 'Choose later',
    subscription_plan: {
        option_popular: 'Most popular option',
        free: {
            title: 'FREE',
            month_employee: 'by month & employee',
            basic: 'Basic',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Main functions',
            continue_free: 'Continue with the free plan',
        },
        professional: {
            from: 'from',
            month_employee: 'by month & employee',
            professional: 'Professional',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'All Basic features plus:',
            calculate_cost: 'Calculate costs',
        },
        enterprise: {
            title: 'Price on request',
            enterprise: 'Enterprise',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'All Professional features plus:',
            contact_team: 'Contact the sales team',
        },
        calculate_prices: 'Calculate costs',
        company_dimension: 'Company size',
        billing_cycle: 'Billing cycle',
        select_plan: 'Choose plan',
        open_job_positions: 'Published job positions',
        open_hr: 'Open HR',
        price_information: 'Price information',
        enterprise_plan: 'Enterprise Plan',
        recruiting: 'Recruiting',
        monthly_payment: 'Monthly payment',
        yearly: 'annual',
        buy: 'Buy',
    },
    not_working_here_anymore: "He doesn't work here anymore",
    employee_removed: 'Employee successfully removed',
    delete_employee: 'Are you sure you want to remove this employee?',
    talent_search_detail: {
        contact_info: 'Contact details of the reference account',
        talent_score: 'Talent score',
        suggestions: 'Suggestions',
        inferences_map: 'Inferences map',
        soft_skills: 'Soft Skills',
        profile_matching: 'Profile matching your search',
        overview: 'Overview',
        video_curricula: 'Video CV',
        top_hard_skills: 'Top hard skills',
        set_interview: 'Arrange a video interview',
        data_from: 'Data source',
        reliability: 'Reliability',
        personality: 'Personality',
        potential: 'Potential',
        experience: 'Experience',
        leadership: 'Leadership',
        experience_data: 'Experience data',
        sector: 'Sector',
        dimension: 'Dimension',
        companies: 'Companies',
        work_companies: 'Companies where you worked',
        number_working_experience: 'Number of working experiences',
        company_change_frequency: 'Company change frequency',
        companyInfo: 'This profile is managed by the company {company}',
        evaluations: 'Evaluations',
        addEvaluation: 'Add evaluation',
        hard_skills: 'Hard skills',
        professional_qualifications: 'Professional qualification',
        cv: 'cv',
        save_search: {
            title: 'Give your search a name. You can save it and take it back later.',
            search_placeholder: 'Search name',
            title_save_talent: 'Add talents to your search, select them and click save',
        },
        between_and: 'between {from} and {to}',
        every_time: 'every {year} years',
        secondary_qualification: 'Secondary qualification',
        training: 'Training',
        typology: 'Typology',
        summary: 'Summary',
    },
    request_talent_meeting: {
        title: 'Get to know the candidate with a video interview',
        subtitle:
            'Enter three options for the video call. The candidate can propose other dates.',
        info: 'By clicking on send an email will be sent to the candidate.',
        insert_message: 'Insert a message.',
        send_request: 'Send and wait for confirmation',
        add_info: 'Add offer details',
        meeting_requested: 'Meeting requested successfully',
    },
    send_confirmation: {
        title: 'Choose the interview date',
        subtitle:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
        send_request: 'Send and wait for confirmation',
    },
    add_offer_detail: {
        title: 'Offer Details',
        configure_new: 'Configure a new offer',
        duplicate: 'Duplicate an existing offer',
        choose_existing: 'Choose an existing offer',
        next: 'Next',
    },
    create_new_offer: {
        title: 'Information (1of2)',
        ads_title_placeholder: 'Advertisement title',
        opportunity_type: 'Opportunity type√†',
        hiring: 'hiring',
        freelance: 'freelance',
        opportunity_features: 'Opportunity Features',
        less_1_year: 'less than 1 year',
        over_1_year: 'over 1 year',
        job_place: 'Place of work activity',
        remote: 'remotely',
        in_office: 'in office',
        mixed: 'mixed',
        job_address: 'Location Information',
    },
    create_new_offer_2: {
        title: 'Information (2of2)',
        activate_chat: 'Do you want to activate the chat service?',
        yes: 'yes',
        no: 'no',
        send_and_wait_confirmation: 'Send and wait for confirmation',
    },
    talent_search_history: {
        title: 'Saved search history',
        search_placeholder: 'Search',
        table: {
            head: {
                name: 'Search name',
                prifle: 'Profile searched for',
                date: 'Date',
                saved: 'Saved',
                conversed: 'Talk',
            },
        },
    },
    draft_candidate: {
        title: 'You are rejecting a candidate, tell us the reasons to improve artificial intelligence algorithms.',
        subtitle:
            'Until we have verified your company details you will not be able to post job adverts.',
        add_valuation: 'Add evaluation',
    },
    in_selections: 'In selection',
    refused: 'Rejected',
    interview_panel: {
        title: 'Interviews',
        second_interview: 'Second interview',
        interviews_history: 'History of interviews',
        interview: 'Interview',
        interview_status: 'Assessment in progress',
    },
    talent_search_filters: {
        contact_info: 'Contact information',
        talent_score: 'Talent score',
        suggestions: 'Suggestions',
        inferences_map: 'Inferences map',
        experience: 'Experiences',
        soft_skills: 'Soft skills',
        evaluations: 'Evaluations',
    },
    no_employees: 'there are no employees in your company',
    no_jobAnnouncements: 'You have no ads to view.',
    no_jobAnnouncements_create: 'Start by creating an announcement.',
    other_companies_message:
        'We could have companies with a similar name. If you see your company below you can request access.',
    add_service_employer: 'Add employee service',
    add_expertise_area: 'Add area of expertise',
    you_are_asking_to_be_paired: 'You are asking to be paired with a company.',
    file_uploaded_tooltip:
        'Processed files are the uploaded files that Wh processes to be analyzed by Artificial Intelligence algorithms',
    file_completed_tooltip:
        'Wh processed the uploaded files. The files have been indexed and the results appear in your searches according to the processing steps already performed. The process involves several phases, including for example: analysis using Wh AI algorithms, analysis using OpenAI, Enrichment, Inference map, etc.',
    file_errors_tooltip:
        "Wh's algorithms were unable to read the files you uploaded or something went wrong",
    we_are_verifying: 'You almost did it. We are verifying your company data.',
    request_sent_to_company_admin:
        'We have sent your request to the company administrator',
    we_are_verifing_data:
        'We are verifying your company, you will soon be able to publish your first job advert.',
    you_are_asking_for_a_match:
        'You are asking to be matched with the company {companyName}. The company administrator will receive a match notification and will be able to approve or reject it. Make sure you only apply if you actually work with this company.',
    yes_work_for_this_company: 'Yes I work for this company',
    wait_for_the_company_administrator_to_approve:
        'Please wait for your company administrator to approve your request to have access to company features.',
    share_offer_page: 'Share offer page',
    share_offer_page_message:
        'Copy and paste this link to your site to share the page with your job offers',
    share_offer_page_jobs: 'Share single offer',
    share_offer_page_jobs_message:
        'Copy and paste this link to share the offer with potential candidates',
    add_company_after_validation:
        'After validation you can choose whether to continue with the free plan or one of our paid plans.',
    confirm_to_work_in_this_company:
        'I confirm that I have the necessary authorizations to operate for this company',
    click_and_confirm_to_work_in_this_company:
        'By clicking on "start" you declare that you work for this company.',
    your_CV: 'Your CV',
    company_CV: 'Your company CV',
    wh_CV: "WH's CV",
    db_search_type: 'Search database type',
    rate: 'Tariff',
    liked_profile: 'Profiles I like',
    my_offers: 'My offers',
    no_jobAnnouncements_applications: 'There are no applications for this job offer',
    process_status: {
        completed: 'Processed',
        created: 'Created',
        error: 'Error',
        loaded: 'Loaded',
        processing: 'Processing',
        uploaded: 'Uploaded',
    },
    search_saved: 'Search saved successfully',
    talent_saved: 'Talents added to search successfully',
    process_by: 'Processed by',
    business_area_public: 'Public Administration',
    business_area_agency: 'Agency',
    business_area_company: 'Company',
    orderby_correspondence:
        'Click here to see the CVs that have a higher match percentage with your search',
    oderby_lastupdate: 'Click here to see the most recent CVs',
};
export default en;
