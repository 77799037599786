import { defineStore } from 'pinia';
import {
    Qualification,
    Maybe,
    Skill,
    LocationType,
    HiringType,
    JobAnnouncentContractDurantion,
    CompanySearchCvOutput,
    SearchCompanyCvOrigin,
    CompanyUploadCvElaborationDetail,
} from '@/graphql/types/graphql';

export interface SearchTalentState {
    qualification: Maybe<Qualification>;
    skills: Skill[];
    locationType: Maybe<LocationType>;
    city: Maybe<string>;
    hiringType: Maybe<HiringType>;
    contractDuration: Maybe<JobAnnouncentContractDurantion>;
    importantInformation: Maybe<string>;
    experienceYear: Maybe<string>;
    specialCategory: boolean;
    results: CompanySearchCvOutput[];
    origin: SearchCompanyCvOrigin;
    talent: Maybe<CompanyUploadCvElaborationDetail>;
    orderBy: string;
    loadingSearchResult: boolean;
    loadingTalentDetail: boolean;
}

export const useSearchTalentStore = defineStore('searchTalent', {
    state: (): SearchTalentState => {
        return {
            qualification: null,
            skills: [],
            locationType: null,
            city: null,
            hiringType: null,
            contractDuration: null,
            importantInformation: null,
            experienceYear: null,
            specialCategory: false,
            results: [],
            origin: SearchCompanyCvOrigin.Internal,
            talent: null,
            loadingSearchResult: false,
            loadingTalentDetail: false,
            orderBy: 'correspondence',
        };
    },
});
