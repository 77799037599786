const it = {
    create_course: 'Crea corso',
    course_list: 'Lista corsi',
    course_trainer: 'Formatori',
    courses_public: 'Pubblicati',
    courses_reserved: 'Riservati',
    courses_finished: 'Terminati',
    courses_deleted: 'Eliminati',
    courses_workinprogress: 'Bozza',
    public: 'pubblici',
    published: 'pubblici',
    reserved: 'riservati',
    ended: 'terminati',
    deleted: 'eliminati',
    workinprogress: 'in bozza',
    courses: 'Corsi',
    welcome: 'benvenuto/a',
    in_education_area: 'nell’area formazione',
    duplicate_course: 'Duplica corso',
    configure_new_course: 'Configura un nuovo corso',
    configure_start_course: 'Inizia la configurazione del corso',
    ask_help_courses: 'Chiedi aiuto',
    course_help_title: 'Hai bisogno di aiuto per il tuo corso?',
    course_help_text:
        'Ricevi assistenza pratica e consigli individuali gratuiti da un nostro formatore esperto',
    course_information: 'Informazioni del corso',
    course_title: 'Titolo',
    course_subjectarea: 'Area tematica del corso',
    course_place: 'Modalità di erogazione del corso',
    course_online: 'online',
    course_presence: 'in presenza',
    course_hybrid: 'misto',
    course_availability: 'Permessi di fruizione del corso',
    course_availability_table: 'Permessi di fruizione',
    course_public: 'pubblico',
    course_private: 'privato',
    course_type: 'Tipologia di fruizione',
    course_sync: 'sincrona',
    course_async: 'asincrona',
    course_address: 'Informazioni sede',
    course_step_1: 'Configura corso',
    course_info: 'Informazioni corso',
    course_description: 'Descrizione corso',
    course_other_info: 'Ulteriori informazioni',
    course_number_lessons: 'Numero di lezioni',
    course_hours: 'Numero di ore',
    course_min_partecipants: 'Partecipanti min',
    course_max_partecipants: 'Partecipanti max',
    course_select_trainer: 'Seleziona docente',
    course_material: 'Materiali del corso',
    course_visibility: 'Vuoi rendere visibili i partecipanti?',
    course_consult: 'Consulta il corso!',
    course_consult_info:
        'Puoi aggiungere altre informazioni o apportare modifiche in qualsiasi momento.',
    course_summary: 'Riepilogo corso',
    search_course: 'Cerca corso',
    course_area: 'Area tematica',
    course_lessons: 'Lezioni',
    course_hour: 'Ore',
    course_dispensing: 'Erogazione',
    course_registration: 'Richiedi iscrizione',
    course_name: 'Nome corso',
    course_partecipants: 'Partecipanti',
    course_starting_date: 'Data inizio',
    course_desc: 'Descrizione',
    course_teachers: 'Docenti',
    course_partecipate: 'Partecipa',
    course_modality: 'Modalità erogazione',
    course_subscription: 'Le tue iscrizioni',
    course_next_lessons: 'Prossime lezioni',
    course_permissions: 'Permessi di fruizione',
    course_next_lesson: 'Prossima lezione',
    course_update: 'Modifica corso',
    course_update_teachers: 'Modifica formatori',
    course_add_materials: 'Aggiungi materiali',
    course_end: 'Termina corso',
    course_delete: 'Elimina corso',
    course_restore: 'Ripristina corso',
    course_duplicate: 'Duplica corso',
    course_infos: 'Informazioni',
    course_add_partecipants: 'Aggiungi partecipanti',
    course_workinprogress: 'Bozza',
    enrollment: {
        ongoing: 'In corso',
        coming_soon: 'In partenza',
        finished: 'Terminati',
    },
    course_detail: {
        modality: 'Erogazione del corso',
        venue: 'Sede',
        permissions: 'Permessi',
        mode: 'Modalità',
        course_mode: 'Modalità corso',
        download_course_report: 'Scarica il Report del corso',
        course_create_lesson: 'Crea le tue lezioni',
        add_lesson: 'Aggiungi lezione',
        edit_lesson: 'Modifica lezione',
        delete_lesson: 'Elimina lezione',
        new_lesson: 'Nuova lezione',
        lesson_title: 'Titolo della lezione',
        lesson_link: 'Link lezione',
        select_docent: 'Selezione docente',
        hour_start: 'Ora inizio',
        hour_end: 'Ora fine',
        search_user: 'Cerca utente',
        lesson_start: 'Avvia lezione',
        user: 'Utente',
        registration: 'Iscrizione',
        action: 'Azione',
        add_partecipant: 'Aggiungi partecipanti',
        delete_partecipant: 'Elimina partecipanti',
        invite_trainers: 'Invita formatori',
        invite_trainer: 'Invita formatore',
        company: 'Azienda',
        send_email: 'Invio email',
        remove: 'Rimuovi',
        deactivate: 'Disattiva',
        approve: 'Approva',
        add_selected_partecipant: 'Aggiungere i seguenti studenti al corso?',
        delete_selected_partecipant: 'Elimina i seguenti studenti dal corso?',
    },
    subject: 'Oggetto',
    lessons: 'lezioni',
    hours: 'ore',
    partecipants: 'partecipanti min {min} / max {max}',
    from_to: 'da {min} a {max}',
    course_status: {
        draft: 'Bozza',
        private: 'Privato',
        published: 'Pubblico',
    },
    course_timing: {
        sync: 'Sincrona',
        async: 'Asincrona',
    },
    course_location: {
        presence: 'In presenza',
        online: 'Online',
        hybrid: 'Misto',
    },
    job_cover_image_info:
        'Questa immagine sarà visibile nel dettaglio del corso (il formato richiesto è orizzontale)',
    invalid_email: 'Email non valida',
    required_email: 'Campo Email obbligatorio',
    required_name: 'Campo nome obbligatorio',
    collaborator: 'Collaboratore',
    change_role_tooltip:
        'Per rimuovere il formatore vai nella sezione gestione azienda della piattaforma whp',
    request_course_signup: 'Iscrizione corso',
    request_course_signup_info:
        'Per inviare la richiesta di iscrizione premi il pulsante Invia ed attendi la conferma',
    areas: {
        sales_and_marketing: 'Sales & Marketing',
        legal: 'Legal',
        social: 'Social',
        advertising: 'Advertising',
        communication: 'Communication',
        design: 'Design',
        content_creation: 'Content creation',
        audiovision: 'Audiovision',
        data_analytics: 'Data analytics',
        finance: 'Finance',
        web_and_mobile_developing: 'Web & Mobile developing',
        information_technology: 'Information technology',
        management: 'Management',
        human_resources: 'Human resources',
    },
    subject_area: 'area tematica',
    new_lesson_added: 'Nuova lezione inserita',
    lesson_edited: 'Lezione modificata',
    lesson_deleted: 'Lezione eliminata',
    student_status: {
        denied: 'Negata',
        waiting: 'In attesa',
        revoked: 'Revocata',
        abandoned: 'Abbandonato',
        completed: 'Completato',
        subscribed: 'Iscritto',
    },
    published_courses: 'corsi pubblicati',
};

export default it;
