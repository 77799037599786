const en = {
    create_course: 'Create course',
    course_list: 'List of courses',
    course_trainer: 'Trainers',
    courses_public: 'Published',
    courses_reserved: 'Reserved',
    courses_finished: 'Finished',
    courses_deleted: 'Deleted',
    courses_workinprogress: 'Draft',
    public: 'public',
    published: 'public',
    reserved: 'reserved',
    ended: 'terminated',
    deleted: 'deleted',
    workinprogress: 'in draft',
    courses: 'Courses',
    welcome: 'welcome',
    in_education_area: 'in the training area',
    duplicate_course: 'Duplicate course',
    configure_new_course: 'Configure a new course',
    configure_start_course: 'Start course configuration',
    ask_help_courses: 'Ask for help',
    course_help_title: 'Do you need help with your course?',
    course_help_text:
        'Receive free practical help and one-to-one advice from one of our expert trainers',
    course_information: 'Course information',
    course_title: 'Title',
    course_subjectarea: 'Course subject area',
    course_place: 'Course delivery method',
    course_online: 'online',
    course_presence: 'in presence',
    course_hybrid: 'mixed',
    course_availability: 'Permissions to use the course',
    course_availability_table: 'Usage permissions',
    course_public: 'public',
    course_private: 'private',
    course_type: 'Type of use',
    course_sync: 'synchronous',
    course_async: 'asynchronous',
    course_address: 'Venue information',
    course_step_1: 'Configure course',
    course_info: 'Course information',
    course_description: 'Course description',
    course_other_info: 'More information',
    course_number_lessons: 'Number of lessons',
    course_hours: 'Number of hours',
    course_min_partecipants: 'Min participants',
    course_max_partecipants: 'Max participants',
    course_select_trainer: 'Select teacher',
    course_material: 'Course materials',
    course_visibility: 'Do you want to make participants visible?',
    course_consult: 'Consult the course!',
    course_consult_info: 'You can add more information or make changes at any time.',
    course_summary: 'Course summary',
    search_course: 'Search course',
    course_area: 'Topic area',
    course_lessons: 'Lessons',
    course_hour: 'Hours',
    course_dispensing: 'Dispensing',
    course_registration: 'Request registration',
    course_name: 'Course name',
    course_partecipants: 'Participants',
    course_starting_date: 'Starting date',
    course_desc: 'Description',
    course_teachers: 'Teachers',
    course_participate: 'Participate',
    course_modality: 'Delivery mode',
    course_subscription: 'Your subscriptions',
    course_next_lessons: 'Next lessons',
    course_permissions: 'Usage permissions',
    course_next_lesson: 'Next lesson',
    course_update: 'Edit course',
    course_update_teachers: 'Edit trainers',
    course_add_materials: 'Add materials',
    course_end: 'End course',
    course_delete: 'Delete course',
    course_restore: 'Restore course',
    course_duplicate: 'Duplicate course',
    course_infos: 'Information',
    course_add_partecipants: 'Add participants',
    course_workinprogress: 'Draft',
    enrollment: {
        ongoing: 'In progress',
        coming_soon: 'Coming soon',
        finished: 'Finished',
    },
    course_detail: {
        modality: 'Course delivery',
        venue: 'Venue',
        permissions: 'Permissions',
        mode: 'Mode',
        course_mode: 'Course mode',
        download_course_report: 'Download the course report',
        course_create_lesson: 'Create your own lessons',
        add_lesson: 'Add lesson',
        edit_lesson: 'Edit lesson',
        delete_lesson: 'Delete lesson',
        new_lesson: 'New lesson',
        lesson_title: 'Title of the lesson',
        lesson_link: 'Lesson link',
        select_docent: 'Select teacher',
        hour_start: 'Start time',
        hour_end: 'End time',
        search_user: 'Search user',
        lesson_start: 'Start lesson',
        user: 'User',
        registration: 'Registration',
        action: 'Action',
        add_partecipant: 'Add participants',
        delete_partecipant: 'Delete participants',
        invite_trainers: 'Invite trainers',
        invite_trainer: 'Invite trainer',
        company: 'Company',
        send_email: 'Send email',
        remove: 'Remove',
        deactivate: 'Deactivate',
        approve: 'Approve',
        add_selected_partecipant: 'Add the following students to the course?',
        delete_selected_partecipant:
            'Do you want to delete the following students from the course?',
    },
    subject: 'Object',
    lessons: 'lessons',
    hours: 'hours',
    partecipants: 'participants min {min} / max {max}',
    from_to: 'from {min} to {max}',
    course_status: {
        draft: 'Draft',
        private: 'Private',
        public: 'Public',
    },
    course_timing: {
        sync: 'Synchronous',
        async: 'Asynchronous',
    },
    course_location: {
        presence: 'In presence',
        online: 'Online',
        hybrid: 'Mixed',
    },
    job_cover_image_info:
        'This image will be visible in the course detail (the required format is landscape)',
    invalid_email: 'Invalid email',
    required_email: 'Email field required',
    required_name: 'Required name field',
    collaborator: 'Collaborator',
    change_role_tooltip:
        'To remove the trainer go to the company management section of the whp platform',
    request_course_signup: 'Course registration',
    request_course_signup_info:
        'To send the registration request, press the Send button and wait for confirmation',
    areas: {
        sales_and_marketing: 'Sales & Marketing',
        legal: 'Legal',
        social: 'Social',
        advertising: 'Advertising',
        communication: 'Communication',
        design: 'Design',
        content_creation: 'Content creation',
        audiovision: 'Audiovision',
        data_analytics: 'Data analytics',
        finance: 'Finance',
        web_and_mobile_developing: 'Web & Mobile developing',
        information_technology: 'Information technology',
        management: 'Management',
        human_resources: 'Human resources',
    },
    subject_area: 'subject area',
    new_lesson_added: 'New lesson added',
    lesson_edited: 'Lesson edited',
    lesson_deleted: 'Lesson deleted',
    student_status: {
        denied: 'Denied',
        waiting: 'Waiting',
        revoked: 'Revoked',
        abandoned: 'Abandoned',
        completed: 'Completed',
        subscribed: 'Subscribed',
    },
    published_courses: 'published courses',
};
export default en;
